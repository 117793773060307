"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-762cc256"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = {
    key: 0,
    class: "page-load"
};
var vue_3 = require("vue");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    __name: 'thePageLoad',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var isPageLoading = (0, vue_3.computed)(function () { return store.state.isPageLoading; });
        return function (_ctx, _cache) {
            return (isPageLoading.value)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, (0, vue_2.toDisplayString)(isPageLoading.value), 1))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
