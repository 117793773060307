"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-a1080884"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "page" };
var _hoisted_2 = { class: "page__content" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var clientStatus_1 = require("@/constants/clientStatus");
var index_vue_1 = require("@/components/sidebar/index.vue");
var RecoveryNotice_vue_1 = require("@/components/notice/RecoveryNotice.vue");
var BulkProgressBar_vue_1 = require("@/components/mail/BulkProgressBar.vue");
var thePageLoad_vue_1 = require("@/components/thePageLoad.vue");
var ProfileSidebar_vue_1 = require("@/components/sidebar/ProfileSidebar.vue");
var NavigationSidebar_vue_1 = require("@/components/sidebar/NavigationSidebar.vue");
var OnboardingProcess_vue_1 = require("@/components/sidebar/OnboardingProcess.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DefaultLayout',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var loggedIn = (0, vue_3.computed)(function () { return store.getters['auth/isLoggedIn']; });
        var status = (0, vue_3.computed)(function () { return store.state.users.currentUser.client.status; });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("main", _hoisted_1, [
                (0, vue_2.createVNode)(thePageLoad_vue_1.default),
                (0, vue_2.createVNode)(index_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (loggedIn.value && status.value === (0, vue_2.unref)(clientStatus_1.READY))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(ProfileSidebar_vue_1.default, { key: 0 }))
                            : (0, vue_2.createCommentVNode)("", true),
                        (loggedIn.value && status.value === (0, vue_2.unref)(clientStatus_1.ONBOARDING))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(OnboardingProcess_vue_1.default, { key: 1 }))
                            : (0, vue_2.createCommentVNode)("", true),
                        (loggedIn.value && status.value === (0, vue_2.unref)(clientStatus_1.READY))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(NavigationSidebar_vue_1.default, { key: 2 }))
                            : (0, vue_2.createCommentVNode)("", true)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(BulkProgressBar_vue_1.default),
                    (0, vue_2.renderSlot)(_ctx.$slots, "menu"),
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ]),
                (0, vue_2.createVNode)(RecoveryNotice_vue_1.default)
            ]));
        };
    }
});
