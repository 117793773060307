"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTitle = exports.guard = void 0;
var vue_router_1 = require("vue-router");
var store_1 = require("@/store");
var i18n_1 = require("@/plugins/i18n");
var LoginLayout_vue_1 = require("@/layouts/LoginLayout.vue");
var account_vue_1 = require("@/layouts/account.vue");
var DefaultLayout_vue_1 = require("@/layouts/DefaultLayout.vue");
var t = i18n_1.default.global.t;
var routes = [
    {
        path: '/',
        name: 'Home',
        component: function () { return Promise.resolve().then(function () { return require('@/components/mail/BaseMail.vue'); }); },
        meta: { layout: DefaultLayout_vue_1.default, title: 'Home' },
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: function () { return Promise.resolve().then(function () { return require('@/components/authentication/ForgotPassword.vue'); }); },
        meta: {
            public: true,
            layout: LoginLayout_vue_1.default,
            title: t('forgot_password.title'),
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: function () { return Promise.resolve().then(function () { return require('@/components/authentication/LoginForm.vue'); }); },
        meta: { layout: LoginLayout_vue_1.default, title: t('login.title') },
    },
    {
        path: '/onboarding/:token',
        name: 'Onboarding',
        component: function () { return Promise.resolve().then(function () { return require('@/views/onboarding/OnboardingIntroduction.vue'); }); },
        meta: {
            public: true,
            layout: LoginLayout_vue_1.default,
            title: t('onboarding.title'),
        },
    },
    {
        path: '/onboarding/configuration',
        name: 'Onboarding Configuration',
        component: function () { return Promise.resolve().then(function () { return require('@/views/onboarding/OnboardingConfiguration.vue'); }); },
        meta: {
            public: true,
            layout: DefaultLayout_vue_1.default,
            title: t('onboarding.title'),
        },
    },
    {
        path: '/onboarding/deploying',
        name: 'Onboarding Deployment',
        component: function () { return Promise.resolve().then(function () { return require('@/views/onboarding/OnboardingConfiguration.vue'); }); },
        meta: {
            public: true,
            layout: DefaultLayout_vue_1.default,
            title: t('onboarding.title'),
        },
    },
    {
        path: '/onboarding/error',
        name: 'Onboarding Error',
        component: function () { return Promise.resolve().then(function () { return require('@/views/onboarding/OnboardingConfiguration.vue'); }); },
        meta: {
            public: true,
            layout: DefaultLayout_vue_1.default,
            title: t('onboarding.title'),
        },
    },
    {
        path: '/reset-password/:token',
        name: 'Reset Password',
        component: function () { return Promise.resolve().then(function () { return require('@/components/authentication/ResetPassword.vue'); }); },
        meta: {
            public: true,
            layout: LoginLayout_vue_1.default,
            title: t('reset_password.title'),
        },
    },
    {
        path: '/initiate-password/:token',
        name: 'Set Password',
        component: function () { return Promise.resolve().then(function () { return require('@/components/authentication/InitiatePassword.vue'); }); },
        meta: {
            public: true,
            layout: LoginLayout_vue_1.default,
            title: t('initiate_password.title'),
        },
    },
    {
        path: '/account/profile',
        name: 'Account',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/AccountLayout.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.info.title') },
    },
    {
        path: '/account/settings',
        name: 'Account settings',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/AccountSettings.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.settings.title') },
    },
    {
        path: '/account/users',
        name: 'Account users',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/user-overview.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.users.title') },
    },
    {
        path: '/account/users/add',
        name: 'Account add user',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/AccountUserForm.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.add_user.title') },
    },
    {
        path: '/account/users/:id',
        name: 'Account edit user',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/AccountUserForm.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.edit_user.title') },
    },
    {
        path: '/account/templates',
        name: 'Account templates',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/TemplatesOverview.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.templates.title') },
    },
    {
        path: '/account/workflow-configurations',
        name: 'Account workflow configurations',
        component: function () { return Promise.resolve().then(function () { return require('@/views/account/AccountWorkflowConfigurations.vue'); }); },
        meta: { layout: account_vue_1.default, title: t('account.workflow_configurations.title') },
    },
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(process.env.BASE_URL),
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    routes: routes,
});
var statusRoutes = {
    ONBOARDING: 'Onboarding Configuration',
    DEPLOYING: 'Onboarding Deployment',
    ERROR: 'Onboarding Error',
    READY: 'Home',
};
var guard = function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
    var isAuthenticated, status;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                isAuthenticated = localStorage.getItem('store_auth')
                    ? JSON.parse(localStorage.getItem('store_auth')).complete
                    : false;
                if (!(!((_a = to.meta) === null || _a === void 0 ? void 0 : _a.public) && to.name !== 'Login' && !isAuthenticated)) return [3 /*break*/, 1];
                next({ name: 'Login' });
                return [3 /*break*/, 4];
            case 1:
                if (!isAuthenticated) return [3 /*break*/, 3];
                return [4 /*yield*/, store_1.default.state.users.currentUser.client];
            case 2:
                status = (_d.sent()).status;
                if (to.name !== statusRoutes[status] && ((_b = to.meta) === null || _b === void 0 ? void 0 : _b.public)) {
                    next({ name: statusRoutes[status] });
                }
                else if (to.name === 'Home' && ((_c = to.meta) === null || _c === void 0 ? void 0 : _c.public)) {
                    next({ name: 'Home' });
                }
                else {
                    next();
                }
                return [3 /*break*/, 4];
            case 3:
                next();
                _d.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.guard = guard;
var setTitle = function (to, from, next) {
    var _a;
    document.title = ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title) ? "".concat(to.meta.title, " | GIRAF.AI") : 'GIRAF.AI';
    next();
};
exports.setTitle = setTitle;
router.beforeEach(exports.guard);
router.beforeEach(exports.setTitle);
exports.default = router;
