"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Sentry = require("@sentry/vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'TemplateConfigureDynamicField',
    props: {
        templateField: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            templateFieldCopy: JSON.parse(JSON.stringify(this.templateField)),
        };
    },
    inject: ['$helpers'],
    methods: {
        cancel: function () {
            this.$emit('close');
        },
        save: function () {
            try {
                this.$store.dispatch('templates/patchTemplateFields', this.templateFieldCopy);
                this.$helpers.showNotificationMessage({
                    message: this.$t('account.templates.dynamic_fields.configure.success'),
                });
                this.$emit('close');
            }
            catch (e) {
                Sentry.captureException(e);
                this.$helpers.showNotificationMessage({
                    message: this.$t('account.templates.dynamic_fields.configure.error'),
                });
            }
        },
    },
});
