"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mail" };
var MailHeader_vue_1 = require("./MailHeader.vue");
var MailTable_vue_1 = require("./MailTable.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseMail',
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createVNode)(MailHeader_vue_1.default),
                (0, vue_2.createVNode)(MailTable_vue_1.default)
            ]));
        };
    }
});
