"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-19f7e3a2"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "dynamic-fields" };
var _hoisted_2 = { class: "dynamic-fields__header" };
var _hoisted_3 = { class: "form-block__title dynamic-fields__header-title" };
var _hoisted_4 = ["innerHTML"];
var mentionConstants_1 = require("@/constants/mentionConstants");
var TemplateConfigureDynamicField_vue_1 = require("@/components/template/TemplateConfigureDynamicField.vue");
var vue_3 = require("vue");
var oruga_next_1 = require("@oruga-ui/oruga-next");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TemplateDynamicFields',
    props: {
        filteredDynamicFields: {
            type: Array,
            required: true,
        },
    },
    setup: function (__props) {
        var oruga = (0, oruga_next_1.useProgrammatic)().oruga;
        var $bus = (0, vue_3.inject)('$bus');
        var insertMention = function (templateField) {
            $bus.$emit('insertMention', templateField);
        };
        var edit = function (templateField) {
            oruga.modal.open({
                component: TemplateConfigureDynamicField_vue_1.default,
                hasModalCard: false,
                width: '80%',
                trapFocus: true,
                props: { templateField: templateField },
                canCancel: ['x'],
            });
        };
        var getLabel = function (templateField) { return (templateField.label ? mentionConstants_1.PREFIX_SYMBOL + templateField.label : mentionConstants_1.PREFIX_SYMBOL + templateField.id); };
        return function (_ctx, _cache) {
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("h2", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('account.templates.dynamic_fields.title')), 1),
                    (0, vue_2.createElementVNode)("p", {
                        innerHTML: _ctx.$t('account.templates.dynamic_fields.description'),
                        class: "dynamic-fields__header-text"
                    }, null, 8, _hoisted_4)
                ]),
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.filteredDynamicFields, function (templateField) {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                        key: "".concat(templateField.id, "_").concat(templateField.language),
                        class: (0, vue_2.normalizeClass)(["dynamic-fields__field", {
                                'dynamic-fields__field--inline': templateField.type === 'string',
                            }])
                    }, [
                        (0, vue_2.createVNode)(_component_o_button, {
                            label: getLabel(templateField),
                            variant: "primary",
                            class: (0, vue_2.normalizeClass)(["dynamic-fields__button", "dynamic-fields__button--type-".concat(templateField.type)]),
                            onClick: function ($event) { return (insertMention(templateField)); }
                        }, null, 8, ["label", "class", "onClick"]),
                        (templateField.type === 'conditional-map')
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_button, {
                                key: 0,
                                "icon-pack": "giraf",
                                "icon-right": "pen",
                                class: "dynamic-fields__button-edit",
                                onClick: function ($event) { return (edit(templateField)); }
                            }, null, 8, ["onClick"]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ], 2));
                }), 128))
            ]));
        };
    }
});
