"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-365c0632"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "workflow-configurations" };
var _hoisted_2 = { key: 0 };
var _hoisted_3 = {
    key: 0,
    class: "form-block"
};
var _hoisted_4 = { class: "form-block__title-group" };
var _hoisted_5 = { class: "form-block__title" };
var _hoisted_6 = { class: "form-block__subtitle" };
var _hoisted_7 = { class: "column is-3 form-block__label-column" };
var _hoisted_8 = {
    key: 0,
    class: "column is-9"
};
var _hoisted_9 = {
    key: 1,
    class: "column is-9"
};
var _hoisted_10 = { class: "button__label" };
var _hoisted_11 = {
    key: 2,
    class: "form-block"
};
var vuex_1 = require("vuex");
var vue_3 = require("vue");
var vee_validate_1 = require("vee-validate");
var text_field_vue_1 = require("@/components/form/fields/text_field.vue");
var SwitchField_vue_1 = require("@/components/form/fields/SwitchField.vue");
var lodash_1 = require("lodash");
var vue_i18n_1 = require("vue-i18n");
var vue_router_1 = require("vue-router");
var connectorErrorEnum_1 = require("@/constants/connectorErrorEnum");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AccountWorkflowConfigurations',
    setup: function (__props) {
        var _this = this;
        var t = (0, vue_i18n_1.useI18n)().t;
        var store = (0, vuex_1.useStore)();
        var connectors = (0, vue_3.ref)([]);
        var isLoading = (0, vue_3.ref)(true);
        var submittingConnectorId = (0, vue_3.ref)('');
        var initConnectorConfigItems = store.state.connector.connectorConfigs;
        var isConnectorConfigItemsEdited = (function () {
            var patchConnectorConfigItems = connectors.value;
            return !(0, lodash_1.isEqual)(initConnectorConfigItems, patchConnectorConfigItems);
        });
        // Remove the test- prefix from the connector id
        // so the translations are consistent with test data and production data.
        var stripTestPrefix = function (value) { return value.replace('test-', ''); };
        var $helpers = (0, vue_3.inject)('$helpers');
        var isConnectorSaving = function (connectorId) { return submittingConnectorId.value === connectorId; };
        var getTextType = function (type, secret) {
            if (secret === void 0) { secret = true; }
            if (secret) {
                return 'password';
            }
            return type === 'string' ? 'text' : type;
        };
        var getFieldRules = function (type) {
            if (type === 'email') {
                return 'required|email';
            }
            if (type === 'int') {
                return 'required|numeric';
            }
            if (type === 'password') {
                return '';
            }
            return 'required';
        };
        var saveConnector = function (connectorId, connectorConfigs) { return __awaiter(_this, void 0, Promise, function () {
            var connectorIdTranslation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        submittingConnectorId.value = connectorId;
                        return [4 /*yield*/, store.dispatch('connector/patchConnectorConfig', connectorConfigs)];
                    case 1:
                        _a.sent();
                        submittingConnectorId.value = '';
                        connectorIdTranslation = "workflow_configurations.connectors.".concat(stripTestPrefix(connectorId), ".title");
                        $helpers.onFormSuccess({
                            message: t(store.state.connector.error.patch ? 'workflow_configurations.form.error' : 'workflow_configurations.form.success', { connectorName: t(connectorIdTranslation) }),
                            variant: store.state.connector.error.patch ? 'danger' : 'primary',
                        });
                        if (store.state.connector.error.patch) {
                            store.commit('connector/SET_ERROR', { type: connectorErrorEnum_1.default.PATCH, value: false });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var submit = function (connectorId) { return __awaiter(_this, void 0, Promise, function () {
            var initConnectorConfigs, newConnectorConfigs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        initConnectorConfigs = store.state.connector.connectorConfigs.find(function (connectorConfig) { return connectorConfig.connector === connectorId; });
                        newConnectorConfigs = connectors.value.find(function (connectorConfig) { return connectorConfig.connector === connectorId; }) || { connector: connectorId, config: [] };
                        if ((0, lodash_1.isEqual)(newConnectorConfigs, initConnectorConfigs)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, saveConnector(connectorId, newConnectorConfigs)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        (0, vue_3.onMounted)(function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch('connector/getConnectorConfigs')];
                    case 1:
                        _a.sent();
                        connectors.value = JSON.parse(JSON.stringify(store.state.connector.connectorConfigs));
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); });
        (0, vue_router_1.onBeforeRouteLeave)(function (to, from, next) {
            if (!isConnectorConfigItemsEdited()) {
                next();
                return;
            }
            $helpers.confirmDiscardChangesDialog({
                onConfirmCallback: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        next();
                        return [2 /*return*/];
                    });
                }); },
            });
        });
        return function (_ctx, _cache) {
            var _a, _b;
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            var _component_o_loading = (0, vue_2.resolveComponent)("o-loading");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (!isLoading.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                        (((_a = (0, vue_2.unref)(store).state.connector.error) === null || _a === void 0 ? void 0 : _a.connectorConfig) || ((_b = (0, vue_2.unref)(store).state.connector.error) === null || _b === void 0 ? void 0 : _b.connectors))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('workflow_configurations.connector_error')), 1))
                            : (connectors.value)
                                ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, (0, vue_2.renderList)(connectors.value, function (connector) {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vee_validate_1.Form), {
                                        key: connector.connector,
                                        class: "form-block",
                                        "data-test": "workflow-configurations-form",
                                        onSubmit: function ($event) { return (submit(connector.connector)); }
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                                (0, vue_2.createElementVNode)("h1", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.$t("workflow_configurations.connectors.".concat(stripTestPrefix(connector.connector), ".title"))), 1),
                                                (0, vue_2.createElementVNode)("h2", _hoisted_6, (0, vue_2.toDisplayString)(_ctx.$t("workflow_configurations.connectors.".concat(stripTestPrefix(connector.connector), ".subtitle"))), 1)
                                            ]),
                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(connector.config, function (config, configKey) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                    class: "columns",
                                                    key: "".concat(connector.connector, "-").concat(configKey)
                                                }, [
                                                    (0, vue_2.createElementVNode)("div", _hoisted_7, (0, vue_2.toDisplayString)(_ctx.$t("workflow_configurations.connectors_config.".concat(configKey))), 1),
                                                    (config.type === 'string' || config.type === 'int' || config.type === 'email')
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_8, [
                                                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                                                id: "".concat(connector.connector, "-").concat(configKey),
                                                                type: getTextType(config.type, config.secret),
                                                                modelValue: config.value,
                                                                "onUpdate:modelValue": function ($event) { return ((config.value) = $event); },
                                                                name: _ctx.$t("workflow_configurations.connectors_config.".concat(configKey)),
                                                                placeholder: config.type === 'email' || config.secret ? _ctx.$t("account.info.form.".concat(getTextType(config.type, config.secret))) : '',
                                                                rules: getFieldRules(config.secret ? 'password' : config.type)
                                                            }, null, 8, ["id", "type", "modelValue", "onUpdate:modelValue", "name", "placeholder", "rules"])
                                                        ]))
                                                        : (0, vue_2.createCommentVNode)("", true),
                                                    (config.type === 'boolean')
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_9, [
                                                            (0, vue_2.createVNode)(SwitchField_vue_1.default, {
                                                                id: "".concat(connector.connector, "-").concat(configKey),
                                                                modelValue: config.value,
                                                                "onUpdate:modelValue": function ($event) { return ((config.value) = $event); }
                                                            }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
                                                        ]))
                                                        : (0, vue_2.createCommentVNode)("", true)
                                                ]));
                                            }), 128)),
                                            (0, vue_2.createVNode)(_component_o_button, {
                                                variant: "secondary",
                                                "native-type": "submit",
                                                "data-test": "workflow-configurations-save-button",
                                                disabled: isConnectorSaving(connector.connector),
                                                class: (0, vue_2.normalizeClass)({ 'button--loading': isConnectorSaving(connector.connector) })
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createElementVNode)("span", _hoisted_10, (0, vue_2.toDisplayString)(_ctx.$t('general.save')), 1),
                                                    (0, vue_2.createVNode)(_component_o_icon, {
                                                        pack: "giraf",
                                                        icon: "save"
                                                    })
                                                ]; }),
                                                _: 2
                                            }, 1032, ["disabled", "class"])
                                        ]; }),
                                        _: 2
                                    }, 1032, ["onSubmit"]));
                                }), 128))
                                : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_11, (0, vue_2.toDisplayString)(_ctx.$t('workflow_configurations.empty')), 1))
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createVNode)(_component_o_loading, {
                    active: isLoading.value,
                    "onUpdate:active": _cache[0] || (_cache[0] = function ($event) { return ((isLoading).value = $event); }),
                    "full-page": false
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_o_icon, {
                            pack: "giraf",
                            icon: "loading",
                            spin: ""
                        })
                    ]; }),
                    _: 1
                }, 8, ["active"])
            ]));
        };
    }
});
