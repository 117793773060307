"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-3f4a1d2b"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "dialog" };
var _hoisted_2 = {
    key: 0,
    class: "dialog__header"
};
var _hoisted_3 = {
    key: 1,
    class: "dialog__body"
};
var _hoisted_4 = { class: "dialog__footer" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseDialog',
    props: {
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        cancelText: {
            type: String,
            default: 'Cancel',
        },
        confirmText: {
            type: String,
            default: 'Confirm',
        },
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.title)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("header", _hoisted_2, (0, vue_2.toDisplayString)(__props.title), 1))
                    : (0, vue_2.createCommentVNode)("", true),
                (__props.message)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_3, [
                        (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(__props.message), 1)
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("footer", _hoisted_4, [
                    (0, vue_2.createVNode)(_component_o_button, {
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$emit('close', { action: 'cancel' })); })
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.cancelText), 1)
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)(_component_o_button, {
                        onClick: _cache[1] || (_cache[1] = function ($event) { return (_ctx.$emit('close', { action: 'confirm' })); }),
                        variant: "primary"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.confirmText), 1)
                        ]; }),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
