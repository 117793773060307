"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'templatePreview',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
});
