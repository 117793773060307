"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var menu_vue_1 = require("@/components/account/menu.vue");
var DefaultLayout_vue_1 = require("@/layouts/DefaultLayout.vue");
exports.default = {
    name: 'accountLayout',
    components: {
        DefaultLayout: DefaultLayout_vue_1.default,
        AccountMenu: menu_vue_1.default,
    },
};
