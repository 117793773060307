"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Giraf Processor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationApi = exports.ConfigurationApiFactory = exports.ConfigurationApiFp = exports.ConfigurationApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
var ConfigurationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Creates a ConnectorsRequest resource.
         * @summary Get all connectors used in the mail flow for the logged in Client. Config parameter not used.
         * @param {ConnectorsRequest} connectorsRequest The new ConnectorsRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConnectorsRequestCollection: function (connectorsRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'connectorsRequest' is not null or undefined
                            (0, common_1.assertParamExists)('postConnectorsRequestCollection', 'connectorsRequest', connectorsRequest);
                            localVarPath = "/api/connectors_requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(connectorsRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a DataFlowRequest resource.
         * @summary Get dataflow from logged-in client. Parameter is not used.
         * @param {DataFlowRequest} dataFlowRequest The new DataFlowRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDataFlowRequestCollection: function (dataFlowRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'dataFlowRequest' is not null or undefined
                            (0, common_1.assertParamExists)('postDataFlowRequestCollection', 'dataFlowRequest', dataFlowRequest);
                            localVarPath = "/api/data_flow_requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(dataFlowRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a PreProcessorConfigRequest resource.
         * @summary Get preprocessor config for the client the logged in user belongs to.
         * @param {PreProcessorConfigRequest} preProcessorConfigRequest The new PreProcessorConfigRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreProcessorConfigRequestCollection: function (preProcessorConfigRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'preProcessorConfigRequest' is not null or undefined
                            (0, common_1.assertParamExists)('postPreProcessorConfigRequestCollection', 'preProcessorConfigRequest', preProcessorConfigRequest);
                            localVarPath = "/api/pre_processor_config_requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(preProcessorConfigRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a UpdatePreProcessorConfigMessage resource.
         * @summary Update preprocessor config for the client the logged in user belongs to.
         * @param {UpdatePreProcessorConfigMessage} updatePreProcessorConfigMessage The new UpdatePreProcessorConfigMessage resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePreProcessorConfigMessageCollection: function (updatePreProcessorConfigMessage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'updatePreProcessorConfigMessage' is not null or undefined
                            (0, common_1.assertParamExists)('postUpdatePreProcessorConfigMessageCollection', 'updatePreProcessorConfigMessage', updatePreProcessorConfigMessage);
                            localVarPath = "/api/update_pre_processor_config_messages";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePreProcessorConfigMessage, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ConfigurationApiAxiosParamCreator = ConfigurationApiAxiosParamCreator;
/**
 * ConfigurationApi - functional programming interface
 * @export
 */
var ConfigurationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ConfigurationApiAxiosParamCreator)(configuration);
    return {
        /**
         * Creates a ConnectorsRequest resource.
         * @summary Get all connectors used in the mail flow for the logged in Client. Config parameter not used.
         * @param {ConnectorsRequest} connectorsRequest The new ConnectorsRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConnectorsRequestCollection: function (connectorsRequest, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postConnectorsRequestCollection(connectorsRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a DataFlowRequest resource.
         * @summary Get dataflow from logged-in client. Parameter is not used.
         * @param {DataFlowRequest} dataFlowRequest The new DataFlowRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDataFlowRequestCollection: function (dataFlowRequest, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postDataFlowRequestCollection(dataFlowRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a PreProcessorConfigRequest resource.
         * @summary Get preprocessor config for the client the logged in user belongs to.
         * @param {PreProcessorConfigRequest} preProcessorConfigRequest The new PreProcessorConfigRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreProcessorConfigRequestCollection: function (preProcessorConfigRequest, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPreProcessorConfigRequestCollection(preProcessorConfigRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a UpdatePreProcessorConfigMessage resource.
         * @summary Update preprocessor config for the client the logged in user belongs to.
         * @param {UpdatePreProcessorConfigMessage} updatePreProcessorConfigMessage The new UpdatePreProcessorConfigMessage resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePreProcessorConfigMessageCollection: function (updatePreProcessorConfigMessage, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postUpdatePreProcessorConfigMessageCollection(updatePreProcessorConfigMessage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ConfigurationApiFp = ConfigurationApiFp;
/**
 * ConfigurationApi - factory interface
 * @export
 */
var ConfigurationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ConfigurationApiFp)(configuration);
    return {
        /**
         * Creates a ConnectorsRequest resource.
         * @summary Get all connectors used in the mail flow for the logged in Client. Config parameter not used.
         * @param {ConnectorsRequest} connectorsRequest The new ConnectorsRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConnectorsRequestCollection: function (connectorsRequest, options) {
            return localVarFp.postConnectorsRequestCollection(connectorsRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a DataFlowRequest resource.
         * @summary Get dataflow from logged-in client. Parameter is not used.
         * @param {DataFlowRequest} dataFlowRequest The new DataFlowRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDataFlowRequestCollection: function (dataFlowRequest, options) {
            return localVarFp.postDataFlowRequestCollection(dataFlowRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a PreProcessorConfigRequest resource.
         * @summary Get preprocessor config for the client the logged in user belongs to.
         * @param {PreProcessorConfigRequest} preProcessorConfigRequest The new PreProcessorConfigRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreProcessorConfigRequestCollection: function (preProcessorConfigRequest, options) {
            return localVarFp.postPreProcessorConfigRequestCollection(preProcessorConfigRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a UpdatePreProcessorConfigMessage resource.
         * @summary Update preprocessor config for the client the logged in user belongs to.
         * @param {UpdatePreProcessorConfigMessage} updatePreProcessorConfigMessage The new UpdatePreProcessorConfigMessage resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePreProcessorConfigMessageCollection: function (updatePreProcessorConfigMessage, options) {
            return localVarFp.postUpdatePreProcessorConfigMessageCollection(updatePreProcessorConfigMessage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ConfigurationApiFactory = ConfigurationApiFactory;
/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
var ConfigurationApi = /** @class */ (function (_super) {
    __extends(ConfigurationApi, _super);
    function ConfigurationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a ConnectorsRequest resource.
     * @summary Get all connectors used in the mail flow for the logged in Client. Config parameter not used.
     * @param {ConfigurationApiPostConnectorsRequestCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.postConnectorsRequestCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ConfigurationApiFp)(this.configuration).postConnectorsRequestCollection(requestParameters.connectorsRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a DataFlowRequest resource.
     * @summary Get dataflow from logged-in client. Parameter is not used.
     * @param {ConfigurationApiPostDataFlowRequestCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.postDataFlowRequestCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ConfigurationApiFp)(this.configuration).postDataFlowRequestCollection(requestParameters.dataFlowRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a PreProcessorConfigRequest resource.
     * @summary Get preprocessor config for the client the logged in user belongs to.
     * @param {ConfigurationApiPostPreProcessorConfigRequestCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.postPreProcessorConfigRequestCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ConfigurationApiFp)(this.configuration).postPreProcessorConfigRequestCollection(requestParameters.preProcessorConfigRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a UpdatePreProcessorConfigMessage resource.
     * @summary Update preprocessor config for the client the logged in user belongs to.
     * @param {ConfigurationApiPostUpdatePreProcessorConfigMessageCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    ConfigurationApi.prototype.postUpdatePreProcessorConfigMessageCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ConfigurationApiFp)(this.configuration).postUpdatePreProcessorConfigMessageCollection(requestParameters.updatePreProcessorConfigMessage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ConfigurationApi;
}(base_1.BaseAPI));
exports.ConfigurationApi = ConfigurationApi;
