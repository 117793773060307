"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mitt_1 = require("mitt");
var eventBus = {
    install: function (app) {
        var emitter = (0, mitt_1.default)();
        var $bus = {
            $on: emitter.on,
            $once: emitter.once,
            $off: emitter.off,
            $emit: emitter.emit,
        };
        app.provide('$bus', $bus);
    },
};
exports.default = eventBus;
