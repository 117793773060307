"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var TemplatePreview_vue_1 = require("@/components/template/TemplatePreview.vue");
var TemplateDynamicFields_vue_1 = require("@/components/template/TemplateDynamicFields.vue");
var textEditor_vue_1 = require("@/components/mail/textEditor.vue");
var useReplaceDynamicField_1 = require("@/composables/useReplaceDynamicField");
var locales_1 = require("@/constants/locales");
exports.default = (0, vue_1.defineComponent)({
    name: 'accountTemplatesOverview',
    components: {
        TemplateDynamicFields: TemplateDynamicFields_vue_1.default,
        TextEditor: textEditor_vue_1.default,
    },
    setup: function () {
        var _this = this;
        var context = (0, vue_1.getCurrentInstance)();
        var root = context.proxy;
        var $helpers = (0, vue_1.inject)('$helpers');
        var _a = (0, useReplaceDynamicField_1.default)(root), convertBodyToPreviewText = _a.convertBodyToPreviewText, convertBodyToJinjaFormat = _a.convertBodyToJinjaFormat, convertJinjaFormatToHTML = _a.convertJinjaFormatToHTML;
        var availableLanguages = [locales_1.EN_US, locales_1.NL_NL];
        var selectedTemplates = (0, vue_1.reactive)({
            'nl-NL': {
                subject: '',
                body: '',
            },
            'en-US': {
                subject: '',
                body: '',
            },
        });
        var isLoading = (0, vue_1.ref)(false);
        var templateLoaded = (0, vue_1.ref)(false);
        var isEdited = (0, vue_1.ref)(false);
        root.$store.dispatch('templates/getTemplates');
        var template = (0, vue_1.computed)(function () { return root.$store.state.templates.template; });
        var selectedTemplateKey = (0, vue_1.computed)(function () { return root.$store.state.templates.selectedTemplateKey; });
        var selectedLanguage = (0, vue_1.computed)(function () { return root.$store.state.templates.selectedTemplateLanguage; });
        var filteredDynamicFields = (0, vue_1.computed)(function () {
            if (Object.keys(root.$store.state.templates.templateFields).length === 0) {
                return [];
            }
            return root.$store.state.templates.templateFields.fields.filter(function (dynamicField) { return dynamicField.language === selectedLanguage.value; });
        });
        var getTemplateBodies = function () {
            Object.values(template.value).forEach(function (templatesArray) {
                templatesArray.forEach(function (apiTemplate) {
                    if (apiTemplate.language === locales_1.EN_US) {
                        selectedTemplates[locales_1.EN_US].subject = apiTemplate.subject;
                        selectedTemplates[locales_1.EN_US].body = convertJinjaFormatToHTML(apiTemplate.body, apiTemplate.language);
                    }
                    if (apiTemplate.language === locales_1.NL_NL) {
                        selectedTemplates[locales_1.NL_NL].subject = apiTemplate.subject;
                        selectedTemplates[locales_1.NL_NL].body = convertJinjaFormatToHTML(apiTemplate.body, apiTemplate.language);
                    }
                });
            });
        };
        var getSelectedTemplate = function (name) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!isEdited.value) return [3 /*break*/, 2];
                        templateLoaded.value = false;
                        isLoading.value = true;
                        return [4 /*yield*/, root.$store.dispatch('templates/getTemplate', name)];
                    case 1:
                        _a.sent();
                        getTemplateBodies();
                        isLoading.value = false;
                        (0, vue_1.nextTick)(function () {
                            templateLoaded.value = true;
                        });
                        return [2 /*return*/];
                    case 2:
                        $helpers.confirmDiscardChangesDialog({
                            onConfirmCallback: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            templateLoaded.value = false;
                                            isLoading.value = true;
                                            return [4 /*yield*/, root.$store.dispatch('templates/getTemplate', name)];
                                        case 1:
                                            _a.sent();
                                            getTemplateBodies();
                                            isLoading.value = false;
                                            (0, vue_1.nextTick)(function () {
                                                isEdited.value = false;
                                                templateLoaded.value = true;
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        var setSelectedLanguage = function (language) {
            if (!isEdited.value) {
                root.$store.commit('templates/setSelectedTemplateLanguage', language);
                getTemplateBodies();
                (0, vue_1.nextTick)(function () {
                    isEdited.value = false;
                });
                return;
            }
            $helpers.confirmDiscardChangesDialog({
                onConfirmCallback: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        root.$store.commit('templates/setSelectedTemplateLanguage', language);
                        getTemplateBodies();
                        (0, vue_1.nextTick)(function () {
                            isEdited.value = false;
                        });
                        return [2 /*return*/];
                    });
                }); },
            });
        };
        (0, vue_1.onMounted)(function () {
            if (selectedTemplateKey.value === '') {
                return;
            }
            getSelectedTemplate(selectedTemplateKey.value);
        });
        (0, vue_1.watch)(selectedTemplates, function () {
            if (!templateLoaded.value) {
                return;
            }
            isEdited.value = true;
        });
        var showPreview = function () {
            var templateBodyText = convertBodyToPreviewText(selectedTemplates[selectedLanguage.value].body);
            // @ts-ignore
            root.$oruga.modal.open({
                component: TemplatePreview_vue_1.default,
                props: { text: templateBodyText },
                hasModalCard: false,
                width: '60%',
                trapFocus: true,
            });
        };
        var saveTemplate = function () { return __awaiter(_this, void 0, Promise, function () {
            var selectedTemplatesClone;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedTemplatesClone = JSON.parse(JSON.stringify(selectedTemplates));
                        Object.entries(selectedTemplatesClone).forEach(function (_a) {
                            var language = _a[0], templateItem = _a[1];
                            selectedTemplatesClone[language].body = convertBodyToJinjaFormat(templateItem.body, language);
                        });
                        return [4 /*yield*/, root.$store.dispatch('templates/updateTemplates', {
                                selectedTemplatesClone: selectedTemplatesClone,
                                selectedTemplateKey: selectedTemplateKey,
                            })];
                    case 1:
                        _a.sent();
                        isEdited.value = false;
                        $helpers.showNotificationMessage({
                            message: root.$t('account.templates.success'),
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            selectedTemplateKey: selectedTemplateKey,
            selectedLanguage: selectedLanguage,
            selectedTemplates: selectedTemplates,
            availableLanguages: availableLanguages,
            isLoading: isLoading,
            templateLoaded: templateLoaded,
            isEdited: isEdited,
            filteredDynamicFields: filteredDynamicFields,
            getSelectedTemplate: getSelectedTemplate,
            setSelectedLanguage: setSelectedLanguage,
            getTemplateBodies: getTemplateBodies,
            showPreview: showPreview,
            saveTemplate: saveTemplate,
        };
    },
    beforeRouteLeave: function (to, from, next) {
        var _this = this;
        if (!this.isEdited) {
            next();
            return;
        }
        this.$helpers.confirmDiscardChangesDialog({
            onConfirmCallback: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    next();
                    return [2 /*return*/];
                });
            }); },
        });
    },
    inject: ['$helpers'],
    computed: __assign({}, (0, vuex_1.mapState)(['templates'])),
});
