"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    name: 'InitiatePassword',
    computed: __assign({}, (0, vuex_1.mapState)({
        reset: function (state) { return state.reset; },
    })),
    data: function () {
        return {
            password: '',
        };
    },
    methods: {
        submit: function () {
            var resetData = {
                plainPassword: this.password,
                resetToken: this.$route.params.token,
            };
            this.$store.dispatch('reset/submit', {
                data: resetData,
                router: this.$router,
            });
        },
    },
});
