"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vee_validate_1 = require("vee-validate");
exports.default = (0, vue_1.defineComponent)({
    name: 'SelectField',
    components: {
        Field: vee_validate_1.Field,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: undefined,
        },
        options: {
            type: [Array, Object],
            default: undefined,
        },
        placeholder: {
            type: String,
            default: '',
        },
        optionValueField: {
            type: String,
            default: 'id',
        },
        optionLabelField: {
            type: String,
            default: 'description',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
