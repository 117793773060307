"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var Sentry = require("@sentry/vue");
var getDefaultState = function () { return ({
    viewsReady: false,
    viewItems: [],
    view: {},
}); };
var getContentField = function (layout) {
    var contentObject = layout.find(function (item) { return item.properties.content; });
    return contentObject === null || contentObject === void 0 ? void 0 : contentObject.field;
};
var getMailResponse = function (layoutResponse, mail) {
    if (layoutResponse !== undefined) {
        var contentFieldResponse = getContentField(layoutResponse);
        if (contentFieldResponse !== undefined) {
            return mail[contentFieldResponse];
        }
    }
    return '';
};
var Views = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },
        setViewItems: function (state, payload) {
            state.viewItems = payload;
            localStorage.setItem('viewItems', JSON.stringify(payload));
            state.viewsReady = true;
        },
        setView: function (state, payload) {
            state.view = payload;
            localStorage.setItem(payload.id, JSON.stringify(payload));
            state.viewsReady = true;
        },
    },
    actions: {
        initializeViews: function (_a) {
            var commit = _a.commit, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var storageViewItems;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            storageViewItems = localStorage.getItem('viewItems');
                            if (!storageViewItems) return [3 /*break*/, 1];
                            commit('setViewItems', JSON.parse(storageViewItems));
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, dispatch('getViews')];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        initializeView: function (_a, _b) {
            var state = _a.state, dispatch = _a.dispatch, commit = _a.commit;
            var router = _b.router;
            return __awaiter(this, void 0, void 0, function () {
                var currentView, storageView;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!(state.viewItems.length === 0)) return [3 /*break*/, 2];
                            return [4 /*yield*/, dispatch('initializeViews')];
                        case 1:
                            _c.sent();
                            _c.label = 2;
                        case 2:
                            currentView = state.viewItems[0];
                            if (router.currentRoute.value.query.view !== undefined) {
                                currentView = state.viewItems.find(function (view) { return view.id === router.currentRoute.value.query.view; });
                            }
                            // Return if view not found in current state.
                            if (currentView === undefined) {
                                return [2 /*return*/];
                            }
                            // Set current view in route.
                            if (!router.currentRoute.value.query.view
                                || router.currentRoute.value.query.view !== currentView.id) {
                                router.replace({
                                    name: 'Home',
                                    query: { view: currentView.id },
                                });
                                return [2 /*return*/];
                            }
                            storageView = localStorage.getItem(currentView.id);
                            if (!storageView) return [3 /*break*/, 3];
                            commit('setView', JSON.parse(storageView));
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, dispatch('getView', currentView.id)];
                        case 4:
                            _c.sent();
                            _c.label = 5;
                        case 5: return [4 /*yield*/, dispatch('mails/getMails', {
                                state: currentView.state,
                                page: 1,
                                itemsPerPage: 25,
                            }, { root: true })];
                        case 6:
                            _c.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        changeView: function (_a, view) {
            var dispatch = _a.dispatch, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var storageView;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            storageView = localStorage.getItem(view.id);
                            if (!storageView) return [3 /*break*/, 1];
                            commit('setView', JSON.parse(storageView));
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, dispatch('getView', view.id)];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3: return [4 /*yield*/, dispatch('mails/getMails', { state: view.state, page: 1, itemsPerPage: 25 }, { root: true })];
                        case 4:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        getViews: function (_a) {
            var commit = _a.commit, getters = _a.getters;
            var viewApi = getters.getViewApi;
            var viewsRequest = { includeCounts: true };
            var apiResponse = viewApi.postViewsRequestCollection({
                viewsRequest: viewsRequest,
            });
            return apiResponse
                .then(function (response) {
                var viewItems = response.data.viewItems
                    .sort(function (a, b) { return (
                // eslint-disable-next-line no-nested-ternary
                a.position > b.position ? 1
                    : (b.position > a.position ? -1 : 0)); });
                commit('setViewItems', viewItems);
            })
                .catch(function (error) {
                Sentry.captureException(error);
            });
        },
        getView: function (context, view) {
            var viewApi = context.getters.getViewApi;
            var viewRequest = { viewId: view };
            var apiResponse = viewApi.postViewRequestCollection({
                viewRequest: viewRequest,
            });
            return apiResponse
                .then(function (response) {
                context.commit('setView', response.data);
            })
                .catch(function (error) {
                Sentry.captureException(error);
            });
        },
    },
    getters: {
        getViewApi: function (state, getters, rootState, rootGetters) {
            return new api_1.ViewApi(rootGetters.getConfiguration);
        },
        getStateFromCurrentView: function (state) {
            var _a;
            return (_a = state.viewItems
                .find(function (viewItem) { return viewItem.id === state.view.id; })) === null || _a === void 0 ? void 0 : _a.state;
        },
        getCurrentView: function (state) {
            return state.viewItems
                .find(function (viewItem) { return viewItem.id === state.view.id; });
        },
        getVerifyItemWithCategoryFromView: function (state) { return function (category) { return state.view.verify
            .find(function (verifyItem) { return verifyItem.category === category; }); }; },
        getVerifyItemsWithCategoryFromView: function (state) { return function (category) { return state.view.verify
            .filter(function (verifyItem) { return verifyItem.category === category; }); }; },
        getBulkOptionFromView: function (state) { var _a; return (_a = state.view.confirm.bulk) !== null && _a !== void 0 ? _a : true; },
        getCurrentViewMailResponse: function (state) { return function (mail) { return getMailResponse(state.view.layout.sections.response, mail); }; },
    },
};
exports.default = Views;
