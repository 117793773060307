"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Giraf Processor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
var AuthenticationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Two factor login, after a valid login the JWT token will be set as a secure cookie
         * @summary Two Factor Login
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (inlineObject, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/login_check";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a ConfigApiTokenRequest resource.
         * @summary Message to request a JWT token to access the config API.
         * @param {ConfigApiTokenRequest} configApiTokenRequest The new ConfigApiTokenRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigApiTokenRequestCollection: function (configApiTokenRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'configApiTokenRequest' is not null or undefined
                            (0, common_1.assertParamExists)('postConfigApiTokenRequestCollection', 'configApiTokenRequest', configApiTokenRequest);
                            localVarPath = "/api/config_api_token_requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(configApiTokenRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Log the current user out by invalidating their JWT token. The logout field in the message body may contain any value and is not used.
         * @summary Invalidate JWT token
         * @param {Logout} logout The new Logout resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogoutCollection: function (logout, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'logout' is not null or undefined
                            (0, common_1.assertParamExists)('postLogoutCollection', 'logout', logout);
                            localVarPath = "/api/logouts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(logout, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a PasswordReset resource.
         * @summary Reset a user\'s password.
         * @param {PasswordReset} passwordReset The new PasswordReset resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetCollection: function (passwordReset, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'passwordReset' is not null or undefined
                            (0, common_1.assertParamExists)('postPasswordResetCollection', 'passwordReset', passwordReset);
                            localVarPath = "/api/password_resets";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(passwordReset, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Creates a ResetRequest resource.
         * @summary Request a reset email to be sent.
         * @param {ResetRequest} resetRequest The new ResetRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetRequestCollection: function (resetRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, Promise, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'resetRequest' is not null or undefined
                            (0, common_1.assertParamExists)('postResetRequestCollection', 'resetRequest', resetRequest);
                            localVarPath = "/api/reset_requests";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication apiKey required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication apiKey required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AuthenticationApiAxiosParamCreator = AuthenticationApiAxiosParamCreator;
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
var AuthenticationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AuthenticationApiAxiosParamCreator)(configuration);
    return {
        /**
         * Two factor login, after a valid login the JWT token will be set as a secure cookie
         * @summary Two Factor Login
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (inlineObject, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.login(inlineObject, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a ConfigApiTokenRequest resource.
         * @summary Message to request a JWT token to access the config API.
         * @param {ConfigApiTokenRequest} configApiTokenRequest The new ConfigApiTokenRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigApiTokenRequestCollection: function (configApiTokenRequest, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postConfigApiTokenRequestCollection(configApiTokenRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Log the current user out by invalidating their JWT token. The logout field in the message body may contain any value and is not used.
         * @summary Invalidate JWT token
         * @param {Logout} logout The new Logout resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogoutCollection: function (logout, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postLogoutCollection(logout, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a PasswordReset resource.
         * @summary Reset a user\'s password.
         * @param {PasswordReset} passwordReset The new PasswordReset resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetCollection: function (passwordReset, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postPasswordResetCollection(passwordReset, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creates a ResetRequest resource.
         * @summary Request a reset email to be sent.
         * @param {ResetRequest} resetRequest The new ResetRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetRequestCollection: function (resetRequest, options) {
            return __awaiter(this, void 0, Promise, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postResetRequestCollection(resetRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AuthenticationApiFp = AuthenticationApiFp;
/**
 * AuthenticationApi - factory interface
 * @export
 */
var AuthenticationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AuthenticationApiFp)(configuration);
    return {
        /**
         * Two factor login, after a valid login the JWT token will be set as a secure cookie
         * @summary Two Factor Login
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (inlineObject, options) {
            return localVarFp.login(inlineObject, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a ConfigApiTokenRequest resource.
         * @summary Message to request a JWT token to access the config API.
         * @param {ConfigApiTokenRequest} configApiTokenRequest The new ConfigApiTokenRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigApiTokenRequestCollection: function (configApiTokenRequest, options) {
            return localVarFp.postConfigApiTokenRequestCollection(configApiTokenRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Log the current user out by invalidating their JWT token. The logout field in the message body may contain any value and is not used.
         * @summary Invalidate JWT token
         * @param {Logout} logout The new Logout resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogoutCollection: function (logout, options) {
            return localVarFp.postLogoutCollection(logout, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a PasswordReset resource.
         * @summary Reset a user\'s password.
         * @param {PasswordReset} passwordReset The new PasswordReset resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordResetCollection: function (passwordReset, options) {
            return localVarFp.postPasswordResetCollection(passwordReset, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creates a ResetRequest resource.
         * @summary Request a reset email to be sent.
         * @param {ResetRequest} resetRequest The new ResetRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetRequestCollection: function (resetRequest, options) {
            return localVarFp.postResetRequestCollection(resetRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AuthenticationApiFactory = AuthenticationApiFactory;
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
var AuthenticationApi = /** @class */ (function (_super) {
    __extends(AuthenticationApi, _super);
    function AuthenticationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Two factor login, after a valid login the JWT token will be set as a secure cookie
     * @summary Two Factor Login
     * @param {AuthenticationApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.login = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.AuthenticationApiFp)(this.configuration).login(requestParameters.inlineObject, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a ConfigApiTokenRequest resource.
     * @summary Message to request a JWT token to access the config API.
     * @param {AuthenticationApiPostConfigApiTokenRequestCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.postConfigApiTokenRequestCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.AuthenticationApiFp)(this.configuration).postConfigApiTokenRequestCollection(requestParameters.configApiTokenRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Log the current user out by invalidating their JWT token. The logout field in the message body may contain any value and is not used.
     * @summary Invalidate JWT token
     * @param {AuthenticationApiPostLogoutCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.postLogoutCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.AuthenticationApiFp)(this.configuration).postLogoutCollection(requestParameters.logout, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a PasswordReset resource.
     * @summary Reset a user\'s password.
     * @param {AuthenticationApiPostPasswordResetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.postPasswordResetCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.AuthenticationApiFp)(this.configuration).postPasswordResetCollection(requestParameters.passwordReset, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creates a ResetRequest resource.
     * @summary Request a reset email to be sent.
     * @param {AuthenticationApiPostResetRequestCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.postResetRequestCollection = function (requestParameters, options) {
        var _this = this;
        return (0, exports.AuthenticationApiFp)(this.configuration).postResetRequestCollection(requestParameters.resetRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthenticationApi;
}(base_1.BaseAPI));
exports.AuthenticationApi = AuthenticationApi;
