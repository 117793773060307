"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-a5b19056"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "onboarding-configuration" };
var _hoisted_2 = { class: "onboarding-configuration__info" };
var _hoisted_3 = { class: "onboarding-configuration__info-title" };
var _hoisted_4 = { class: "onboarding-configuration__info-step" };
var _hoisted_5 = { class: "onboarding-configuration__info-email" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var onboarding_steps_1 = require("@/constants/onboarding-steps");
exports.default = (0, vue_1.defineComponent)({
    __name: 'OnboardingProcess',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var step = (0, vue_3.computed)(function () { return store.state.onboarding.step; });
        return function (_ctx, _cache) {
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_o_icon, {
                    class: "onboarding-configuration__icon",
                    pack: "giraf",
                    icon: "settings"
                }),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("span", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.configuration.configuration_process')), 1),
                    (0, vue_2.createElementVNode)("span", _hoisted_4, (0, vue_2.toDisplayString)("".concat(_ctx.$t('onboarding.configuration.step'), " ").concat(step.value, "/").concat((0, vue_2.unref)(onboarding_steps_1.default).TOTAL_STEPS)), 1),
                    (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.$t('enum.mails.info')), 1)
                ])
            ]));
        };
    }
});
