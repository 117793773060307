"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var Recovery = {
    namespaced: true,
    state: {
        codes: [],
    },
    mutations: {
        codes: function (state, payload) {
            state.codes = payload;
        },
    },
    getters: {
        getRecoveryApi: function (state, getters, rootState, rootGetters) {
            return new api_1.RecoveryCodeApi(rootGetters.getConfiguration);
        },
    },
    actions: {
        generate: function (_a) {
            var commit = _a.commit, getters = _a.getters;
            var recoveryCodeApi = getters.getRecoveryApi;
            recoveryCodeApi
                .postRecoveryCodeCollection({ recoveryCode: { count: 5 } })
                .then(function (response) {
                commit('codes', response.data.codes);
            });
        },
    },
};
exports.default = Recovery;
