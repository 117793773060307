"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var getDefaultState = function () { return ({
    currentUser: {},
}); };
var Users = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },
        setCurrentUser: function (state, user) {
            state.currentUser = user;
        },
        setCurrentUserProfile: function (state, profile) {
            state.currentUser.profile = profile;
        },
        removeUserFromCreatedUsers: function (state, userId) {
            var index = state.currentUser.createdUsers
                .map(function (item) { return item.id; })
                .indexOf(userId);
            state.currentUser.createdUsers.splice(index, 1);
        },
    },
    actions: {
        postUserLocale: function (_a, _b) {
            var getters = _a.getters, commit = _a.commit;
            var data = _b.data;
            return __awaiter(this, void 0, void 0, function () {
                var userApi;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            userApi = getters.getUserApi;
                            return [4 /*yield*/, userApi.postUserLocaleCollection({ userLocale: data })];
                        case 1:
                            _c.sent();
                            commit('auth/UPDATE_LOCALE', data, { root: true });
                            return [2 /*return*/];
                    }
                });
            });
        },
        getUser: function (_a, _b) {
            var getters = _a.getters;
            var id = _b.id;
            return __awaiter(this, void 0, void 0, function () {
                var userApi, response;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            userApi = getters.getUserApi;
                            return [4 /*yield*/, userApi.getUserItem({ id: id })];
                        case 1:
                            response = _c.sent();
                            return [2 /*return*/, response.data];
                    }
                });
            });
        },
        postUser: function (_a, _b) {
            var getters = _a.getters;
            var data = _b.data;
            return __awaiter(this, void 0, void 0, function () {
                var userApi;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            userApi = getters.getUserApi;
                            return [4 /*yield*/, userApi.postUserCollection({ user: data })];
                        case 1:
                            _c.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteUser: function (_a, _b) {
            var getters = _a.getters;
            var id = _b.id;
            return __awaiter(this, void 0, void 0, function () {
                var userApi;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            userApi = getters.getUserApi;
                            return [4 /*yield*/, userApi.deleteUserItem({ id: id })];
                        case 1:
                            _c.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        getMeUser: function (_a) {
            var getters = _a.getters, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var userApi, response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            userApi = getters.getUserApi;
                            return [4 /*yield*/, userApi.getMeUserCollection()];
                        case 1:
                            response = _b.sent();
                            commit('setCurrentUser', response.data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        putProfile: function (_a, profile) {
            var getters = _a.getters, commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var profileApi, profileCopy, response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            profileApi = getters.getProfileApi;
                            profileCopy = JSON.parse(JSON.stringify(profile));
                            return [4 /*yield*/, profileApi.putProfileItem({
                                    id: profileCopy.id,
                                    profile: profileCopy,
                                })];
                        case 1:
                            response = _b.sent();
                            if (state.currentUser.profile.id === profileCopy.id) {
                                commit('setCurrentUserProfile', response.data);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    getters: {
        getUserApi: function (state, getters, rootState, rootGetters) {
            return new api_1.UserApi(rootGetters.getConfiguration);
        },
        getProfileApi: function (state, getters, rootState, rootGetters) {
            return new api_1.ProfileApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Users;
