"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var downloadString_1 = require("@/util/downloadString");
exports.default = (0, vue_1.defineComponent)({
    name: 'RecoveryCodes',
    created: function () {
        this.$store.dispatch('recovery/generate');
    },
    methods: {
        download: function () {
            (0, downloadString_1.default)(this.$store.state.recovery.codes.join('\n'), 'plain/text', 'recovery_codes.txt');
        },
    },
});
