"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var getDefaultState = function () { return ({
    mailList: [],
    selectedMails: [],
    selectedMailsSentCount: 0,
    sendType: '',
    showMailsSentStatus: false,
    isBulkMailSendingCompleted: false,
    isBulkMailSendingErrored: false,
    mails: {},
    mail: {},
    loaded: false,
    overviewLoaded: false,
}); };
var ORDER_FIELDNAME_MAPPING = {
    'mail.received': 'orderDate',
    'mail.sender': 'orderFrom',
    'mail.subject': 'orderSubject',
    'processor_data.pararius_address': 'orderProcessorDataParariusAddress',
};
var Mails = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },
        RESET_SELECTED_MAILS: function (state, shouldResetActiveMail) {
            if (shouldResetActiveMail === void 0) { shouldResetActiveMail = true; }
            state.mails = {};
            if (shouldResetActiveMail) {
                state.mail = {};
            }
            state.selectedMails = [];
            state.selectedMailsSentCount = 0;
        },
        SET_MAIL_LIST: function (state, list) {
            state.mailList = list;
        },
        SET_MAIL: function (state, mail) {
            state.mails[mail.id] = mail;
            state.mail = __assign({ id: mail.id }, mail.details);
            state.loaded = true;
        },
        SET_MAIL_REPLY: function (state, _a) {
            var contentFieldResponse = _a.contentFieldResponse, value = _a.value;
            state.mail[contentFieldResponse] = value;
        },
        UNSET_MAIL: function (state, mailId) {
            delete state.mails[mailId];
        },
        SET_SELECTED_MAILS: function (state, mails) {
            state.selectedMails = mails;
        },
        INCREMENT_SELECTED_COUNT: function (state) {
            state.selectedMailsSentCount += 1;
        },
        SET_SELECTED_SENDING: function (state, value) {
            state.showMailsSentStatus = value;
        },
        SET_SELECTED_SENDTYPE: function (state, mailType) {
            state.sendType = mailType;
        },
        SET_BULK_SENDING_COMPLETE: function (state, value) {
            state.isBulkMailSendingCompleted = value;
        },
        SET_BULK_SENDING_ERROR: function (state, value) {
            state.isBulkMailSendingErrored = value;
        },
        REMOVE_FROM_LIST: function (state, id) {
            state.mailList.splice(id, 1);
        },
        RESET_BULK_STATES: function (state) {
            state.isBulkMailSendingCompleted = false;
            state.isBulkMailSendingErrored = false;
        },
        SET_LOADED: function (state, value) {
            state.loaded = value;
        },
        SET_OVERVIEW_LOADED: function (state, value) {
            state.overviewLoaded = value;
        },
    },
    actions: {
        loadMail: function (context, id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!(id in context.state.mails)) return [3 /*break*/, 2];
                            return [4 /*yield*/, context.dispatch('getMail', id)];
                        case 1: return [2 /*return*/, (_a.sent()).details];
                        case 2:
                            context.commit('SET_MAIL', context.state.mails[id]);
                            context.commit('SET_LOADED', true);
                            return [2 /*return*/, Promise.resolve(context.state.mails[id].details)];
                    }
                });
            });
        },
        getMails: function (_a, payload) {
            var commit = _a.commit, getters = _a.getters;
            if (payload === void 0) { payload = {}; }
            commit('SET_OVERVIEW_LOADED', false);
            var mailApi = getters.getMailApi;
            var requestParams = {
                state: payload.state,
                page: payload.page,
                itemsPerPage: payload.itemsPerPage,
            };
            if (payload.sortField) {
                var sortField = ORDER_FIELDNAME_MAPPING[payload.sortField];
                requestParams[sortField] = payload.sortOrder;
            }
            var apiResponse = mailApi.getMailCollection(requestParams);
            apiResponse.then(function (response) {
                commit('SET_MAIL_LIST', response.data);
                commit('SET_OVERVIEW_LOADED', true);
            });
        },
        getMail: function (context, id) {
            return __awaiter(this, void 0, Promise, function () {
                var mailApi, response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            mailApi = context.getters.getMailApi;
                            return [4 /*yield*/, mailApi.getMailItem({ id: id })];
                        case 1:
                            response = _a.sent();
                            context.commit('SET_MAIL', response.data);
                            return [2 /*return*/, Promise.resolve(response.data)];
                    }
                });
            });
        },
        unsetMail: function (_a, mailId) {
            var commit = _a.commit;
            commit('UNSET_MAIL', mailId);
        },
    },
    getters: {
        getMailApi: function (state, getters, rootState, rootGetters) {
            return new api_1.MailApi(rootGetters.getConfiguration);
        },
        isMailSending: function (state) { return function (mailId) {
            var _a;
            return (_a = state.selectedMails
                .find(function (selectedMail) { return selectedMail.mailId === mailId; })) === null || _a === void 0 ? void 0 : _a.sending;
        }; },
    },
};
exports.default = Mails;
