"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_fns_1 = require("date-fns");
function useDate() {
    var dateFormat = 'dd.MM.yyyy | HH:mm';
    var formatDate = function (input) {
        if (!input) {
            return '';
        }
        var date = Number.isNaN(Number(input))
            ? (0, date_fns_1.parseISO)(input.toString())
            : new Date(Number(input) * 1000);
        return (0, date_fns_1.format)(date, dateFormat);
    };
    return {
        formatDate: formatDate,
    };
}
exports.default = useDate;
