"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    class: "form-block",
    "data-test": "account-user-form"
};
var _hoisted_2 = { class: "form-block__title-group" };
var _hoisted_3 = { class: "form-block__title" };
var _hoisted_4 = { class: "columns" };
var _hoisted_5 = { class: "column is-3 form-block__label-column" };
var _hoisted_6 = { class: "column is-9" };
var _hoisted_7 = { class: "columns" };
var _hoisted_8 = { class: "column is-3 form-block__label-column" };
var _hoisted_9 = { class: "column is-9" };
var _hoisted_10 = { class: "columns" };
var _hoisted_11 = { class: "column is-6" };
var _hoisted_12 = { class: "column is-6" };
var _hoisted_13 = {
    key: 0,
    class: "form-error"
};
var _hoisted_14 = { class: "form-footer" };
var _hoisted_15 = { class: "button__label" };
var _hoisted_16 = { class: "button__label" };
var vue_3 = require("vue");
var vee_validate_1 = require("vee-validate");
var text_field_vue_1 = require("@/components/form/fields/text_field.vue");
var vuex_1 = require("vuex");
var vue_router_1 = require("vue-router");
var vue_i18n_1 = require("vue-i18n");
var Sentry = require("@sentry/vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AccountUserForm',
    setup: function (__props) {
        var _this = this;
        var $helpers = (0, vue_3.inject)('$helpers');
        var route = (0, vue_router_1.useRoute)();
        var router = (0, vue_router_1.useRouter)();
        var store = (0, vuex_1.useStore)();
        var t = (0, vue_i18n_1.useI18n)().t;
        var user = (0, vue_3.ref)({
            id: null,
            email: '',
            profile: {
                firstName: '',
                lastName: '',
            },
        });
        var error = (0, vue_3.ref)(false);
        var isLoading = (0, vue_3.ref)(false);
        var isNewUser = (0, vue_3.computed)(function () { return user.value.id === null; });
        var resetForm = function () {
            user.value = {
                id: null,
                email: '',
                profile: {
                    firstName: '',
                    lastName: '',
                },
            };
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        };
        var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isLoading.value = true;
                        error.value = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!isNewUser.value) return [3 /*break*/, 3];
                        return [4 /*yield*/, store.dispatch('users/postUser', {
                                data: {
                                    email: user.value.email,
                                    firstName: user.value.profile.firstName,
                                    lastName: user.value.profile.lastName,
                                },
                            })];
                    case 2:
                        _a.sent();
                        resetForm();
                        router.push('/account/users');
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, store.dispatch('users/putProfile', user.value.profile)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        $helpers.onFormSuccess({
                            message: t(isNewUser.value ? 'account.add_user.form.success' : 'account.edit_user.form.success'),
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        Sentry.captureException(e_1);
                        error.value = true;
                        $helpers.onFormSuccess({
                            message: t('account.add_user.form.error'),
                            variant: 'danger',
                        });
                        return [3 /*break*/, 7];
                    case 7:
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        (0, vue_3.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!route.params.id) return [3 /*break*/, 2];
                        _a = user;
                        return [4 /*yield*/, store.dispatch('users/getUser', {
                                id: route.params.id,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
        return function (_ctx, _cache) {
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", null, [
                (0, vue_2.createVNode)((0, vue_2.unref)(vee_validate_1.Form), { onSubmit: onSubmit }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createElementVNode)("div", _hoisted_1, [
                            (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                (0, vue_2.createElementVNode)("h1", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t(isNewUser.value ? 'account.add_user.title' : 'account.edit_user.title')), 1)
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                (0, vue_2.createElementVNode)("div", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.$t('account.info.form.email')) + "* ", 1),
                                (0, vue_2.createElementVNode)("div", _hoisted_6, [
                                    (0, vue_2.createVNode)(text_field_vue_1.default, {
                                        id: "user_email",
                                        type: 'email',
                                        modelValue: user.value.email,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((user.value.email) = $event); }),
                                        name: _ctx.$t('account.info.form.email'),
                                        placeholder: _ctx.$t('account.info.form.email'),
                                        rules: "required|email",
                                        disabled: !isNewUser.value
                                    }, null, 8, ["modelValue", "name", "placeholder", "disabled"])
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_7, [
                                (0, vue_2.createElementVNode)("div", _hoisted_8, (0, vue_2.toDisplayString)(_ctx.$t('account.info.form.name')) + "* ", 1),
                                (0, vue_2.createElementVNode)("div", _hoisted_9, [
                                    (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                        (0, vue_2.createElementVNode)("div", _hoisted_11, [
                                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                                id: "user_firstname",
                                                autocomplete: 'given-name',
                                                modelValue: user.value.profile.firstName,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((user.value.profile.firstName) = $event); }),
                                                name: _ctx.$t('account.info.form.firstname'),
                                                placeholder: _ctx.$t('account.info.form.firstname'),
                                                rules: "required"
                                            }, null, 8, ["modelValue", "name", "placeholder"])
                                        ]),
                                        (0, vue_2.createElementVNode)("div", _hoisted_12, [
                                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                                id: "user_lastname",
                                                autocomplete: 'family-name',
                                                modelValue: user.value.profile.lastName,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((user.value.profile.lastName) = $event); }),
                                                name: _ctx.$t('account.info.form.lastname'),
                                                placeholder: _ctx.$t('account.info.form.lastname'),
                                                rules: "required"
                                            }, null, 8, ["modelValue", "name", "placeholder"])
                                        ])
                                    ])
                                ])
                            ]),
                            (error.value)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_13, (0, vue_2.toDisplayString)(_ctx.$t(isNewUser.value ? 'account.add_user.form.error' : 'account.edit_user.form.error')), 1))
                                : (0, vue_2.createCommentVNode)("", true)
                        ]),
                        (0, vue_2.createElementVNode)("div", _hoisted_14, [
                            (0, vue_2.createVNode)(_component_o_button, {
                                variant: "cancel",
                                tag: "router-link",
                                to: '/account/users'
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createElementVNode)("span", _hoisted_15, (0, vue_2.toDisplayString)(_ctx.$t('general.cancel')), 1)
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_o_button, {
                                variant: "secondary",
                                "native-type": "submit",
                                class: (0, vue_2.normalizeClass)({ 'button--loading': isLoading.value }),
                                "data-test": "account-user-form-submit"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createElementVNode)("span", _hoisted_16, (0, vue_2.toDisplayString)(_ctx.$t(isNewUser.value ? 'account.add_user.form.button' : 'account.edit_user.form.button')), 1)
                                ]; }),
                                _: 1
                            }, 8, ["class"])
                        ])
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
