"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-01f885a6"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "navigation" };
var _hoisted_2 = { class: "navigation__top" };
var _hoisted_3 = { class: "submenu-item__text" };
var _hoisted_4 = { class: "submenu-item__count" };
var _hoisted_5 = { class: "navigation__bottom" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'NavigationSidebar',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var route = (0, vue_router_1.useRoute)();
        var router = (0, vue_router_1.useRouter)();
        var views = (0, vue_3.computed)(function () { return store.state.views; });
        var onViewChange = function (view) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        store.commit('mails/SET_SELECTED_MAILS', []);
                        return [4 /*yield*/, store.dispatch('views/getViews')];
                    case 1:
                        _b.sent();
                        if (!(view.id === views.value.view.id && route.name === 'Home')) return [3 /*break*/, 3];
                        return [4 /*yield*/, store.dispatch('mails/getMails', {
                                state: (_a = store.getters['views/getCurrentView']) === null || _a === void 0 ? void 0 : _a.state,
                            })];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                    case 3: return [4 /*yield*/, router.push({ name: 'Home', query: { view: view.id } })];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var isActive = function (path) { return route.fullPath.includes(path); };
        var logout = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch('auth/logout')];
                    case 1:
                        _a.sent();
                        router.push('/login');
                        return [2 /*return*/];
                }
            });
        }); };
        (0, vue_3.onMounted)(function () {
            store.dispatch('views/getViews');
        });
        return function (_ctx, _cache) {
            var _component_o_menu_item = (0, vue_2.resolveComponent)("o-menu-item");
            var _component_o_menu_list = (0, vue_2.resolveComponent)("o-menu-list");
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(_component_o_menu_list, null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(views.value.viewItems, function (view) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_menu_item, {
                                    active: _ctx.$route.path === '/' && view.id === views.value.view.id,
                                    key: view.id,
                                    "icon-pack": "giraf",
                                    icon: 'view-' + view.id,
                                    class: "submenu-item",
                                    onClick: function ($event) { return (onViewChange(view)); }
                                }, {
                                    label: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createElementVNode)("span", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('menu.' + view.id)), 1),
                                        (0, vue_2.createElementVNode)("span", _hoisted_4, "(" + (0, vue_2.toDisplayString)(view.count) + ")", 1)
                                    ]; }),
                                    _: 2
                                }, 1032, ["active", "icon", "onClick"]));
                            }), 128))
                        ]; }),
                        _: 1
                    })
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    (0, vue_2.createVNode)(_component_o_menu_list, null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_o_menu_item, {
                                tag: "router-link",
                                to: "/account/profile",
                                "icon-pack": "giraf",
                                icon: "settings",
                                class: "submenu-item",
                                active: isActive('/account')
                            }, {
                                label: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.$t('menu.settings')), 1),
                                    (0, vue_2.createVNode)(_component_o_icon, {
                                        pack: "giraf",
                                        icon: "arrow-right",
                                        size: "small",
                                        class: "icon--settings"
                                    })
                                ]; }),
                                _: 1
                            }, 8, ["active"]),
                            (0, vue_2.createVNode)(_component_o_menu_item, {
                                "icon-pack": "giraf",
                                icon: "sign-out",
                                class: "submenu-item",
                                label: _ctx.$t('menu.logout'),
                                onClick: _cache[0] || (_cache[0] = function ($event) { return (logout()); })
                            }, null, 8, ["label"])
                        ]; }),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
