"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var mentionConstants_1 = require("@/constants/mentionConstants");
exports.default = (0, vue_1.defineComponent)({
    props: {
        items: {
            type: Array,
            required: true,
        },
        command: {
            type: Function,
            required: true,
        },
    },
    data: function () {
        return {
            selectedIndex: 0,
        };
    },
    watch: {
        items: function () {
            this.selectedIndex = 0;
        },
    },
    methods: {
        onKeyDown: function (_a) {
            var event = _a.event;
            if (event.key === 'ArrowUp') {
                this.upHandler();
                return true;
            }
            if (event.key === 'ArrowDown') {
                this.downHandler();
                return true;
            }
            if (event.key === 'Enter') {
                this.enterHandler();
                return true;
            }
            return false;
        },
        upHandler: function () {
            this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
        },
        downHandler: function () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
        },
        enterHandler: function () {
            this.selectItem(this.selectedIndex);
        },
        selectItem: function (index) {
            var item = this.items[index];
            if (item) {
                this.command({
                    'dynamic-field-type': item.type,
                    id: item.id,
                    label: item.label ? item.label : '',
                    class: item.type ? "".concat(mentionConstants_1.MENTION_CLASS, "--type-").concat(item.type) : '',
                });
            }
        },
    },
});
