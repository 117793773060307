"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_3_1 = require("@tiptap/vue-3");
var starter_kit_1 = require("@tiptap/starter-kit");
var extension_underline_1 = require("@tiptap/extension-underline");
var extension_link_1 = require("@tiptap/extension-link");
var suggestion_1 = require("@/components/mail/suggestion");
var customMention_1 = require("@/components/mail/customMention");
var vue_1 = require("vue");
var mentionConstants_1 = require("@/constants/mentionConstants");
exports.default = (0, vue_1.defineComponent)({
    name: 'textEditor',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        editable: {
            type: Boolean,
            default: false,
        },
        enableDynamicFields: {
            type: Boolean,
            default: false,
        },
        suggestionItems: {
            type: Array,
            default: function () { return []; },
            required: false,
        },
    },
    components: {
        EditorContent: vue_3_1.EditorContent,
    },
    data: function () { return ({
        editor: null,
        emitAfterOnUpdate: false,
    }); },
    inject: ['$bus'],
    mounted: function () {
        this.createEditor();
        this.$bus.$on('insertMention', this.insertMention);
    },
    watch: {
        modelValue: function () {
            if (this.emitAfterOnUpdate) {
                this.emitAfterOnUpdate = false;
                return;
            }
            if (this.editor) {
                // Re-initialize editor to get clear history.
                this.editor.destroy();
                this.createEditor();
            }
        },
        editable: function () {
            this.editor.setOptions({ editable: this.editable });
        },
    },
    methods: {
        createEditor: function () {
            var _this = this;
            if (this.enableDynamicFields) {
                suggestion_1.default.items = function (_a) {
                    var query = _a.query;
                    return _this.suggestionItems
                        // @todo this check can be removed (and all other label checks) when the back-end
                        //  has a label key.
                        .filter(function (item) { return (item.label
                        ? item.label.toLowerCase().startsWith(query.toLowerCase())
                        : item.id.toLowerCase().startsWith(query.toLowerCase())); })
                        .slice(0, 5);
                };
            }
            var addMention = function (isShown) {
                if (isShown) {
                    return customMention_1.default.configure({
                        HTMLAttributes: {
                            class: mentionConstants_1.MENTION_CLASS,
                        },
                        suggestion: suggestion_1.default,
                        renderLabel: function (_a) {
                            var options = _a.options, node = _a.node;
                            return "".concat(options.suggestion.char).concat(node.attrs.label || node.attrs.id);
                        },
                    });
                }
                return undefined;
            };
            this.editor = new vue_3_1.Editor({
                onUpdate: function () {
                    _this.emitAfterOnUpdate = true;
                    _this.$emit('update:model-value', _this.editor.getHTML());
                },
                content: this.replaceWhiteLinesWithBr(this.modelValue),
                extensions: [
                    starter_kit_1.default.configure({
                        blockquote: false,
                        codeBlock: false,
                        heading: false,
                        horizontalRule: false,
                        orderedList: false,
                        code: false,
                        strike: false,
                        dropcursor: false,
                        gapcursor: false,
                    }),
                    extension_underline_1.default,
                    extension_link_1.default,
                    addMention(this.enableDynamicFields),
                ],
                editable: this.editable,
            });
        },
        replaceWhiteLinesWithBr: function (string) {
            return string.replace(/\r?\n/g, '<br>');
        },
        insertMention: function (templateField) {
            this.editor.commands.insertContent({
                type: 'mention',
                attrs: {
                    id: templateField.id,
                    label: templateField.label ? templateField.label : '',
                    class: templateField.type ? "".concat(mentionConstants_1.MENTION_CLASS, "--type-").concat(templateField.type) : '',
                    'dynamic-field-type': templateField.type,
                },
            });
            this.editor.commands.focus();
        },
    },
    beforeUnmount: function () {
        if (this.editor) {
            this.editor.destroy();
        }
        this.$bus.$off('insertMention', this.insertMention);
    },
});
