"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    name: 'RecoveryNotice',
    mounted: function () {
        // temporary disabled, check GIRAF-179 for more details
        // this.showRecoveryPopUp();
    },
    computed: __assign({}, (0, vuex_1.mapState)(['auth'])),
    methods: {
        showRecoveryPopUp: function () {
            var _this = this;
            if (this.auth.login.recoveryCode === 0) {
                // @todo Fix, when recovery codes feature is used (was snackbar)
                this.$oruga.notification.open({
                    message: this.$t('recovery_codes.pop_up.message'),
                    actionText: this.$t('recovery_codes.pop_up.action_text'),
                    variant: 'danger',
                    position: 'bottom-left',
                    duration: 10000,
                    container: '.notice-recovery',
                    onAction: function () {
                        _this.$router.push('/account/recovery-codes');
                    },
                });
            }
        },
    },
});
