"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-5e48df3d"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = {
    key: 0,
    class: "bulk-progress-bar"
};
var _hoisted_2 = { class: "bulk-progress-bar__wrapper" };
var _hoisted_3 = { class: "bulk-progress-bar__text" };
var _hoisted_4 = ["value", "max"];
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var vue_i18n_1 = require("vue-i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BulkProgressBar',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var t = (0, vue_i18n_1.useI18n)().t;
        var total = (0, vue_3.computed)(function () { return store.state.mails.selectedMails.length; });
        var mailsSent = (0, vue_3.computed)(function () { return store.state.mails.selectedMailsSentCount; });
        var status = (0, vue_3.computed)(function () { return store.state.mails.showMailsSentStatus; });
        var message = (0, vue_3.computed)(function () { return t("table.mails.sending_mail_".concat(store.state.mails.sendType)); });
        return function (_ctx, _cache) {
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            return (status.value)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createVNode)(_component_o_icon, {
                        class: "bulk-progress-bar__icon animate-left-right",
                        pack: "giraf",
                        icon: "sending-mail",
                        size: "small"
                    }),
                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(message.value) + " (" + (0, vue_2.toDisplayString)(mailsSent.value) + "/" + (0, vue_2.toDisplayString)(total.value) + ") ", 1),
                        (0, vue_2.createElementVNode)("progress", {
                            class: "bulk-progress-bar__progress progress",
                            value: mailsSent.value,
                            max: total.value
                        }, null, 8, _hoisted_4)
                    ])
                ]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
