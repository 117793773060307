"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-1af09f6d"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "onboarding-introduction login-form" };
var _hoisted_2 = { class: "onboarding-introduction__login" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var EmailCheck_vue_1 = require("@/components/onboarding/EmailCheck.vue");
var AccountCreation_vue_1 = require("@/components/onboarding/AccountCreation.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'OnboardingIntroduction',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var isValidated = (0, vue_3.computed)(function () { return store.state.onboarding.validated; });
        return function (_ctx, _cache) {
            var _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.already_have_account')) + " ", 1),
                    (0, vue_2.createVNode)(_component_router_link, {
                        to: { name: 'Login' },
                        class: "onboarding-introduction__login-link"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.login')), 1)
                        ]; }),
                        _: 1
                    })
                ]),
                (!isValidated.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(EmailCheck_vue_1.default, { key: 0 }))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(AccountCreation_vue_1.default, { key: 1 }))
            ]));
        };
    }
});
