"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'BaseFeedback',
    props: {
        warning: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {};
    },
});
