"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OnboardingSteps;
(function (OnboardingSteps) {
    OnboardingSteps[OnboardingSteps["COMPANY_DETAILS"] = 1] = "COMPANY_DETAILS";
    OnboardingSteps[OnboardingSteps["ACCOUNT_DETAILS"] = 2] = "ACCOUNT_DETAILS";
    OnboardingSteps[OnboardingSteps["MAILBOX_AUTHENTICATION"] = 3] = "MAILBOX_AUTHENTICATION";
    OnboardingSteps[OnboardingSteps["TOTAL_STEPS"] = 3] = "TOTAL_STEPS";
})(OnboardingSteps || (OnboardingSteps = {}));
exports.default = OnboardingSteps;
