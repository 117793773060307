"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-22f4c7e1"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "onboarding-configuration" };
var _hoisted_2 = { class: "onboarding-configuration__menu" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var BaseHeader_vue_1 = require("@/components/onboarding/configuration/BaseHeader.vue");
var OnboardingDetails_vue_1 = require("@/components/onboarding/configuration/OnboardingDetails.vue");
var onboarding_steps_1 = require("@/constants/onboarding-steps");
exports.default = (0, vue_1.defineComponent)({
    __name: 'OnboardingConfiguration',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var currentStep = (0, vue_3.computed)(function () { return store.state.onboarding.step; });
        var loggedIn = (0, vue_3.computed)(function () { return store.getters['auth/isLoggedIn']; });
        if (loggedIn.value) {
            store.dispatch('translationLocales/FETCH_TRANSLATION_LOCALES');
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(onboarding_steps_1.default).TOTAL_STEPS, function (step) {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            key: step,
                            class: (0, vue_2.normalizeClass)(["onboarding-configuration__menu-button", { active: step === currentStep.value }])
                        }, (0, vue_2.toDisplayString)("".concat(step, ". ").concat(_ctx.$t("onboarding.configuration.step_".concat(step, ".title")))), 3));
                    }), 128))
                ]),
                (0, vue_2.createVNode)(BaseHeader_vue_1.default, {
                    title: _ctx.$t("onboarding.configuration.step_".concat(currentStep.value, ".title")),
                    description: _ctx.$t("onboarding.configuration.step_".concat(currentStep.value, ".description"))
                }, null, 8, ["title", "description"]),
                (0, vue_2.createVNode)(OnboardingDetails_vue_1.default)
            ]));
        };
    }
});
