"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_i18n_1 = require("vue-i18n");
var enUS = require("@/locales/en-US.json");
var nlNL = require("@/locales/nl-NL.json");
var locale = localStorage.getItem('locale') || 'nl-NL';
var fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US';
exports.default = (0, vue_i18n_1.createI18n)({
    locale: locale,
    legacy: false,
    fallbackLocale: fallbackLocale,
    messages: {
        'en-US': enUS,
        'nl-NL': nlNL,
    },
    warnHtmlMessage: false,
});
