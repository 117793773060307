"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var locales_1 = require("@/constants/locales");
var getDefaultState = function () { return ({
    templateFields: {},
    templates: [],
    template: {},
    selectedTemplateKey: '',
    selectedTemplateLanguage: locales_1.EN_US,
}); };
var Templates = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: function (state) {
            Object.assign(state, getDefaultState());
        },
        setTemplates: function (state, payload) {
            state.templates = payload;
        },
        setTemplate: function (state, payload) {
            state.template = payload;
        },
        setTemplateFields: function (state, payload) {
            state.templateFields = payload;
        },
        setSelectedtemplateKey: function (state, key) {
            state.selectedTemplateKey = key;
        },
        setSelectedTemplateLanguage: function (state, language) {
            state.selectedTemplateLanguage = language;
        },
    },
    actions: {
        getTemplates: function (_a) {
            var commit = _a.commit, getters = _a.getters;
            return __awaiter(this, void 0, void 0, function () {
                var templatesApi, templatesRequest, response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            templatesApi = getters.getTemplatesApi;
                            templatesRequest = { config: '' };
                            return [4 /*yield*/, templatesApi.postTemplatesRequestCollection({
                                    templatesRequest: templatesRequest,
                                })];
                        case 1:
                            response = _b.sent();
                            // @ts-ignore
                            commit('setTemplates', response.data.templates);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getTemplate: function (_a, payload) {
            var commit = _a.commit, getters = _a.getters, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var templatesApi, templateRequest, response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setSelectedtemplateKey', payload);
                            templatesApi = getters.getTemplatesApi;
                            templateRequest = { name: payload };
                            return [4 /*yield*/, templatesApi.postTemplateRequestCollection({
                                    templateRequest: templateRequest,
                                })];
                        case 1:
                            response = _b.sent();
                            return [4 /*yield*/, dispatch('getTemplateFields', payload)];
                        case 2:
                            _b.sent();
                            commit('setTemplate', response.data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getTemplateFields: function (_a, payload) {
            var commit = _a.commit, getters = _a.getters;
            return __awaiter(this, void 0, void 0, function () {
                var templatesApi, response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            templatesApi = getters.getTemplatesApi;
                            return [4 /*yield*/, templatesApi.getTemplateFieldsItem({
                                    template: payload,
                                })];
                        case 1:
                            response = _b.sent();
                            commit('setTemplateFields', response.data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateTemplates: function (_a, payload) {
            var getters = _a.getters, state = _a.state, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var templatesApi, updateTemplateMessage;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            templatesApi = getters.getTemplatesApi;
                            updateTemplateMessage = {
                                name: payload.selectedTemplateKey.value,
                                templates: [
                                    {
                                        language: locales_1.EN_US,
                                        body: payload.selectedTemplatesClone[locales_1.EN_US].body,
                                        subject: payload.selectedTemplatesClone[locales_1.EN_US].subject,
                                    },
                                    {
                                        language: locales_1.NL_NL,
                                        body: payload.selectedTemplatesClone[locales_1.NL_NL].body,
                                        subject: payload.selectedTemplatesClone[locales_1.NL_NL].subject,
                                    },
                                ],
                            };
                            return [4 /*yield*/, templatesApi.postUpdateTemplateMessageCollection({
                                    updateTemplateMessage: updateTemplateMessage,
                                })];
                        case 1:
                            _b.sent();
                            dispatch('getTemplate', state.selectedTemplateKey);
                            return [2 /*return*/];
                    }
                });
            });
        },
        patchTemplateFields: function (_a, payload) {
            var state = _a.state, getters = _a.getters, dispatch = _a.dispatch;
            return __awaiter(this, void 0, void 0, function () {
                var templatesApi, templateFields;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            templatesApi = getters.getTemplatesApi;
                            templateFields = {
                                template: state.selectedTemplateKey,
                                fields: [payload],
                            };
                            return [4 /*yield*/, templatesApi.patchTemplateFieldsItem({
                                    template: state.selectedTemplateKey,
                                    templateFields: templateFields,
                                })];
                        case 1:
                            _b.sent();
                            dispatch('getTemplateFields', state.selectedTemplateKey);
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    getters: {
        getTemplatesApi: function (state, getters, rootState, rootGetters) {
            return new api_1.TemplatesApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Templates;
