"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseDialog_vue_1 = require("@/components/BaseDialog.vue");
var oruga_next_1 = require("@oruga-ui/oruga-next");
var dialogOptions = {
    component: BaseDialog_vue_1.default,
    trapFocus: true,
    width: 380,
    canCancel: false,
    contentClass: 'modal-content--dialog',
};
var oruga = (0, oruga_next_1.useProgrammatic)().oruga;
exports.default = {
    install: function (app, options) {
        var $t = options.$t;
        var $helpers = {
            onFormSuccess: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.message, message = _c === void 0 ? $t('general.form_saved_successfully') : _c, _d = _b.variant, variant = _d === void 0 ? 'primary' : _d, _e = _b.position, position = _e === void 0 ? 'top-right' : _e, _f = _b.duration, duration = _f === void 0 ? 3000 : _f;
                oruga.notification.open({
                    message: message,
                    variant: variant,
                    position: position,
                    duration: duration,
                });
            },
            showNotificationMessage: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.message, message = _c === void 0 ? $t('detail.response_sent.success') : _c, _d = _b.variant, variant = _d === void 0 ? 'primary' : _d, _e = _b.position, position = _e === void 0 ? 'bottom-right' : _e, _f = _b.duration, duration = _f === void 0 ? 5000 : _f;
                oruga.notification.open({
                    message: message,
                    variant: variant,
                    duration: duration,
                    position: position,
                });
            },
            confirmDeleteDialog: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.title, title = _c === void 0 ? $t('delete_confirmation.title') : _c, _d = _b.message, message = _d === void 0 ? $t('delete_confirmation.message') : _d, _e = _b.cancelText, cancelText = _e === void 0 ? $t('delete_confirmation.cancel') : _e, _f = _b.confirmText, confirmText = _f === void 0 ? $t('delete_confirmation.delete') : _f, _g = _b.onConfirmCallback, onConfirmCallback = _g === void 0 ? function () { } : _g;
                var instance = oruga.modal.open(__assign(__assign({}, dialogOptions), { props: {
                        title: title,
                        message: message,
                        cancelText: cancelText,
                        confirmText: confirmText,
                    } }));
                instance.promise.then(function (_a) {
                    var action = _a.action;
                    if (action === 'confirm') {
                        onConfirmCallback();
                    }
                });
            },
            confirmDiscardChangesDialog: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.title, title = _c === void 0 ? $t('discard_changes.title') : _c, _d = _b.message, message = _d === void 0 ? $t('discard_changes.message') : _d, _e = _b.cancelText, cancelText = _e === void 0 ? $t('discard_changes.cancel') : _e, _f = _b.confirmText, confirmText = _f === void 0 ? $t('discard_changes.confirm') : _f, _g = _b.onConfirmCallback, onConfirmCallback = _g === void 0 ? function () { } : _g;
                var instance = oruga.modal.open(__assign(__assign({}, dialogOptions), { props: {
                        title: title,
                        message: message,
                        cancelText: cancelText,
                        confirmText: confirmText,
                    } }));
                instance.promise.then(function (_a) {
                    var action = _a.action;
                    if (action === 'confirm') {
                        onConfirmCallback();
                    }
                });
            },
        };
        app.provide('$helpers', $helpers);
    },
};
