"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_1 = require("vuex");
var vuex_persistedstate_1 = require("vuex-persistedstate");
var api_1 = require("@/api");
var auth_1 = require("@/store/auth");
var forgot_1 = require("@/store/forgot");
var reset_1 = require("@/store/reset");
var recovery_1 = require("@/store/recovery");
var locale_1 = require("@/store/locale");
var views_1 = require("@/store/views");
var mail_1 = require("@/store/mail");
var actionMessage_1 = require("@/store/actionMessage");
var connector_1 = require("@/store/connector");
var correction_1 = require("@/store/correction");
var translationLocales_1 = require("@/store/translationLocales");
var users_1 = require("@/store/users");
var templates_1 = require("@/store/templates");
var onboarding_1 = require("@/store/onboarding");
var store = (0, vuex_1.createStore)({
    strict: true,
    state: {
        apiUrl: process.env.VUE_APP_API_URL,
        isPageLoading: false,
    },
    mutations: {
        setPageLoading: function (state, value) {
            state.isPageLoading = value;
        },
    },
    getters: {
        getConfiguration: function (state) { return new api_1.Configuration({ basePath: state.apiUrl }); },
    },
    actions: {
        resetStore: function (_a) {
            var commit = _a.commit;
            commit('auth/resetState');
            commit('mails/resetState');
            commit('views/resetState');
            commit('templates/resetState');
        },
    },
    modules: {
        auth: auth_1.default,
        connector: connector_1.default,
        correction: correction_1.default,
        forgot: forgot_1.default,
        reset: reset_1.default,
        recovery: recovery_1.default,
        locale: locale_1.default,
        views: views_1.default,
        mails: mail_1.default,
        actionMessage: actionMessage_1.default,
        translationLocales: translationLocales_1.default,
        users: users_1.default,
        templates: templates_1.default,
        onboarding: onboarding_1.default,
    },
    plugins: [
        (0, vuex_persistedstate_1.default)({
            paths: ['users', 'mails'],
        }),
    ],
});
store.subscribe(function (mutation, state) {
    if (mutation.type === 'auth/complete') {
        localStorage.setItem('store_auth', JSON.stringify(state.auth));
    }
});
exports.default = store;
