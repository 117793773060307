import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/core';

const CustomMention = Mention.extend({
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      // Fixed order for the reqular expressions
      mergeAttributes(
        {
          'data-dynamic-field-type': '',
          'data-id': '',
          'data-label': '',
          'data-type': this.name,
        },
        this.options.HTMLAttributes,
        HTMLAttributes,
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },
  addAttributes() {
    return {
      'dynamic-field-type': {
        default: null,
        parseHTML: (element) => {
          if (element.getAttribute('data-dynamic-field-type') === 'undefined') {
            return '';
          }

          return element.getAttribute('data-dynamic-field-type');
        },
        renderHTML: (attributes) => {
          if (!attributes['dynamic-field-type']) {
            return {};
          }
          return {
            'data-dynamic-field-type': attributes['dynamic-field-type'],
          };
        },
      },
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-id': attributes.id,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
});
export default CustomMention;
