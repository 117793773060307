"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var theme_bulma_1 = require("@oruga-ui/theme-bulma");
require('@/icons/icon.font');
var customConfig = {
    defaultFieldLabelPosition: 'on-border',
    defaultContainerElement: '#app',
    customIconPacks: {
        giraf: {
            sizes: {
                default: 'is-small',
                'is-small': 'is-size-5',
                'is-medium': 'is-size-3',
                'is-large': 'is-size-1',
            },
            iconPrefix: 'giraf-',
            internalIcons: {
                'chevron-left': 'arrow-left',
                'chevron-right': 'arrow-right',
            },
        },
    },
    defaultIconPack: 'giraf',
};
var config = __assign(__assign({}, theme_bulma_1.bulmaConfig), customConfig);
exports.default = config;
