"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vee_validate_1 = require("vee-validate");
var rules_1 = require("@vee-validate/rules");
var i18n_1 = require("@/plugins/i18n");
var t = i18n_1.default.global.t;
(0, vee_validate_1.defineRule)('required', rules_1.required);
(0, vee_validate_1.defineRule)('email', rules_1.email);
(0, vee_validate_1.defineRule)('numeric', rules_1.numeric);
(0, vee_validate_1.configure)({
    generateMessage: function (context) { return t("form.validations.".concat(context.rule.name)); },
});
