"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-e95993d6"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "account-creation" };
var _hoisted_2 = { class: "account-creation__heading" };
var _hoisted_3 = { class: "account-creation__heading-title" };
var _hoisted_4 = { class: "account-creation__information-text" };
var _hoisted_5 = { class: "account-creation__form" };
var _hoisted_6 = { class: "account-creation__form-title" };
var _hoisted_7 = { class: "account-creation__footer" };
var _hoisted_8 = ["innerHTML"];
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var vue_router_1 = require("vue-router");
var vee_validate_1 = require("vee-validate");
var text_field_vue_1 = require("@/components/form/fields/text_field.vue");
var BaseFeedback_vue_1 = require("@/components/onboarding/feedback/BaseFeedback.vue");
var Sentry = require("@sentry/vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AccountCreation',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var router = (0, vue_router_1.useRouter)();
        var token = (0, vue_3.computed)(function () { return store.state.onboarding.token; });
        var error = (0, vue_3.ref)(false);
        var credentials = (0, vue_3.reactive)({
            email: '',
            // todo: remove clientId when the endpoint is fixed
            clientId: 'tf.acceptance.dev-rotterdam.giraf.incoming-emails',
            token: token.value,
            plainPassword: '',
        });
        var onSubmit = function () { return __awaiter(_this, void 0, Promise, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, store.dispatch('onboarding/createUser', credentials)];
                    case 1:
                        _a.sent();
                        router.push({ name: 'Login' });
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        Sentry.captureException(e_1);
                        error.value = true;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var cancel = function () {
            store.dispatch('onboarding/setValidated', false);
        };
        return function (_ctx, _cache) {
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("h1", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.account_creation.title')), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.account_creation.choose')), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.account_creation.attention')), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    (0, vue_2.createElementVNode)("div", _hoisted_6, [
                        (0, vue_2.createVNode)(_component_o_icon, {
                            class: "account-creation__form-icon",
                            pack: "giraf",
                            icon: "mail",
                            "custom-size": "8px"
                        }),
                        (0, vue_2.createElementVNode)("strong", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.account_creation.mail_password')), 1)
                    ]),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vee_validate_1.Form), { onSubmit: onSubmit }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                id: "username",
                                type: "email",
                                modelValue: credentials.email,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((credentials.email) = $event); }),
                                name: _ctx.$t('general.username'),
                                placeholder: _ctx.$t('login.username_placeholder'),
                                rules: "required|email"
                            }, null, 8, ["modelValue", "name", "placeholder"]),
                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                id: "password",
                                type: "password",
                                modelValue: credentials.plainPassword,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((credentials.plainPassword) = $event); }),
                                name: _ctx.$t('general.password'),
                                placeholder: _ctx.$t('login.password_placeholder'),
                                rules: "required",
                                "icon-pack": "giraf"
                            }, null, 8, ["modelValue", "name", "placeholder"]),
                            (0, vue_2.createElementVNode)("div", _hoisted_7, [
                                (0, vue_2.createVNode)(_component_o_button, {
                                    onClick: cancel,
                                    class: "account-creation__button ghost",
                                    label: _ctx.$t('onboarding.introduction.account_creation.cancel')
                                }, null, 8, ["label"]),
                                (0, vue_2.createVNode)(_component_o_button, {
                                    variant: "active",
                                    "native-type": "submit",
                                    class: "account-creation__button",
                                    label: _ctx.$t('onboarding.introduction.account_creation.continue')
                                }, null, 8, ["label"])
                            ]),
                            (error.value)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(BaseFeedback_vue_1.default, {
                                    key: 0,
                                    warning: true
                                }, {
                                    main: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createElementVNode)("strong", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.account_creation.error.title')), 1),
                                        (0, vue_2.createElementVNode)("p", {
                                            innerHTML: _ctx.$t('onboarding.introduction.account_creation.error.subtitle')
                                        }, null, 8, _hoisted_8)
                                    ]; }),
                                    _: 1
                                }))
                                : (0, vue_2.createCommentVNode)("", true)
                        ]; }),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
