"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var BaseFeedback_vue_1 = require("@/components/onboarding/feedback/BaseFeedback.vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'MailUnknown',
    components: {
        BaseFeedback: BaseFeedback_vue_1.default,
    },
});
