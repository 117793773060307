"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-432c99ad"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "onboarding-configuration-header" };
var _hoisted_2 = { class: "onboarding-configuration-header__form" };
var _hoisted_3 = { class: "onboarding-configuration-header__details" };
var _hoisted_4 = { class: "onboarding-configuration-header__details-step" };
var _hoisted_5 = { class: "onboarding-configuration-header__details-text" };
var _hoisted_6 = { class: "onboarding-configuration-header__details-title" };
var _hoisted_7 = {
    key: 0,
    class: "onboarding-configuration-header__additional-info"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = { class: "onboarding-configuration-header__list" };
var _hoisted_10 = { class: "onboarding-configuration-header__list-item" };
var _hoisted_11 = ["innerHTML"];
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var onboarding_steps_1 = require("@/constants/onboarding-steps");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseHeader',
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var currentStep = (0, vue_3.computed)(function () { return store.state.onboarding.step; });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("div", _hoisted_4, (0, vue_2.toDisplayString)(currentStep.value), 1),
                        (0, vue_2.createElementVNode)("div", _hoisted_5, [
                            (0, vue_2.createElementVNode)("h3", _hoisted_6, (0, vue_2.toDisplayString)(__props.title), 1),
                            (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(__props.description), 1)
                        ])
                    ]),
                    (currentStep.value === (0, vue_2.unref)(onboarding_steps_1.default).MAILBOX_AUTHENTICATION)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7, [
                            (0, vue_2.createElementVNode)("span", {
                                innerHTML: _ctx.$t('onboarding.configuration.mailbox.note')
                            }, null, 8, _hoisted_8),
                            (0, vue_2.createElementVNode)("ul", _hoisted_9, [
                                (0, vue_2.createElementVNode)("li", _hoisted_10, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.configuration.mailbox.list_item_1')), 1)
                            ]),
                            (0, vue_2.createElementVNode)("span", {
                                innerHTML: _ctx.$t('onboarding.configuration.mailbox.contact'),
                                class: "onboarding-configuration-header__contact"
                            }, null, 8, _hoisted_11)
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ]));
        };
    }
});
