"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mentionConstants_1 = require("@/constants/mentionConstants");
function useReplaceDynamicField(root) {
    // @ts-ignore
    var replaceWithExampleText = function (templateField) {
        if (!templateField.type || templateField.type === 'string') {
            return templateField['example-text'];
        }
        switch (templateField.type) {
            case 'url':
                return "<a href=\"".concat(templateField['example-text'], "\">").concat(templateField['example-text'], "</a>");
            case 'conditional-map': {
                var listItems = Object.values(templateField.map)
                    .map(function (item) { return "<li>".concat(item.value, "</li>"); });
                return "<ul class=\"conditional-map ".concat(templateField.id, "\">").concat(listItems.join(''), "</ul>");
            }
            default:
                break;
        }
        return undefined;
    };
    var getMentionHTML = function (templateField) { return "<span data-dynamic-field-type=\"".concat(templateField.type ? templateField.type : '', "\" data-id=\"").concat(templateField.id, "\" data-label=\"").concat(templateField.label ? templateField.label : '', "\" class=\"").concat(templateField.type ? "".concat(mentionConstants_1.MENTION_CLASS, "--type-").concat(templateField.type) : '', "\" data-type=\"mention\"></span>"); };
    var replaceParagraphsWithBr = function (string) { return string.replace(/<p><\/p>/g, '<br>'); };
    var getTemplateFieldIdRegex = function (templateFieldId) { return new RegExp("{{ ".concat(templateFieldId, "(.*?)}}"), 'g'); };
    var getTemplateFieldTypeRegex = function (templateFieldType, templateFieldId) { return new RegExp("<span data-dynamic-field-type=\"".concat(templateFieldType, "\" data-id=\"").concat(templateFieldId, "\"(.*?)<\\/span>"), 'g'); };
    var convertBodyToPreviewText = function (templateBodyText) {
        var preview = replaceParagraphsWithBr(templateBodyText);
        var dynamicFields = root.$store.state.templates.templateFields;
        var language = root.$store.state.templates.selectedTemplateLanguage;
        dynamicFields.fields.forEach(function (templateField) {
            if (templateField.language !== language) {
                return;
            }
            preview = preview.replace(getTemplateFieldTypeRegex(templateField.type, templateField.id), replaceWithExampleText(templateField) || '');
            preview = preview.replace(getTemplateFieldIdRegex(templateField.id), replaceWithExampleText(templateField) || '');
        });
        return preview;
    };
    var convertJinjaFormatToHTML = function (templateBodyText, language) {
        var dynamicFields = root.$store.state.templates.templateFields;
        var html = templateBodyText;
        dynamicFields.fields.forEach(function (templateField) {
            if (templateField.language !== language) {
                return;
            }
            switch (templateField.type) {
                case 'string':
                    html = html.replace(getTemplateFieldIdRegex(templateField.id), getMentionHTML(templateField));
                    break;
                case 'url':
                    html = html.replace(new RegExp("{% if ".concat(templateField.id, " %}<a(.*?){% endif %}"), 'g'), getMentionHTML(templateField));
                    break;
                case 'conditional-map':
                    html = html.replace(/<ul class="conditional-map (.*?)<\/ul>/g, getMentionHTML(templateField));
                    break;
                default:
                    // No action
                    break;
            }
        });
        return html;
    };
    var convertBodyToJinjaFormat = function (templateBodyText, templateLanguage) {
        var jinjaFormat = templateBodyText;
        var dynamicFields = root.$store.state.templates.templateFields;
        dynamicFields.fields.forEach(function (templateField) {
            if (templateField.language !== templateLanguage) {
                return;
            }
            switch (templateField.type) {
                case 'string':
                    jinjaFormat = jinjaFormat.replace(new RegExp("<span data-dynamic-field-type=\"string\" data-id=\"".concat(templateField.id, "\"(.*?)<\\/span>"), 'g'), (templateField === null || templateField === void 0 ? void 0 : templateField.fallback) && templateField.fallback !== ''
                        ? "{{ ".concat(templateField.id, " or '").concat(templateField.fallback, "' }}")
                        : "{{ ".concat(templateField.id, " }}"));
                    break;
                case 'url':
                    jinjaFormat = jinjaFormat.replace(getTemplateFieldTypeRegex(templateField.type, templateField.id), "{% if ".concat(templateField.id, " %}<a href=\"{{ ").concat(templateField.id, " }}\">{{ ").concat(templateField.id, " }}</a>{% endif %}"));
                    break;
                case 'conditional-map': {
                    var conditionalObjects = Object.entries(templateField.map);
                    var listItems = conditionalObjects.map(function (conditionalObject) {
                        var name = conditionalObject[0];
                        var value = conditionalObject[1].value;
                        return "{% if ".concat(templateField.id, ".").concat(name, " is not none and ").concat(templateField.id, ".").concat(name, " is false %}<li>").concat(value, "</li>{% endif %}");
                    });
                    jinjaFormat = jinjaFormat.replace(getTemplateFieldTypeRegex(templateField.type, templateField.id), "<ul class=\"conditional-map ".concat(templateField.id, "\">").concat(listItems.join(''), "</ul>"));
                    break;
                }
                default:
                    // No action
                    break;
            }
        });
        return jinjaFormat;
    };
    return {
        convertBodyToPreviewText: convertBodyToPreviewText,
        convertBodyToJinjaFormat: convertBodyToJinjaFormat,
        convertJinjaFormatToHTML: convertJinjaFormatToHTML,
        replaceParagraphsWithBr: replaceParagraphsWithBr,
    };
}
exports.default = useReplaceDynamicField;
