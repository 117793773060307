"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vee_validate_1 = require("vee-validate");
exports.default = (0, vue_1.defineComponent)({
    name: 'TextField',
    components: {
        Field: vee_validate_1.Field,
    },
    props: {
        autocomplete: {
            type: String,
            default: 'off',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        fieldType: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        iconPack: {
            type: String,
            default: 'giraf',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getFieldType: function (hasErrors) {
            if (hasErrors) {
                return 'danger';
            }
            return this.fieldType ? this.fieldType : '';
        },
    },
});
