<template>
  <section class="login">
    <section class="login-block">
      <div class="columns is-marginless columns--login">
        <div class="column is-half login-block__image" />
        <div class="column is-half login-block__content">
          <slot />
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.login {
  background-color: $white;
}

.columns {
  &--login {
    height: 100vh;
  }
}

.login-block {
  background-color: $white;
  text-align: left;

  &__image {
    display: none;

    @include tablet {
      display: block;
      background-image: url('~@/static/images/login.jpg');
      background-size: cover;
      background-position: center;
      position: relative;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: -34px;
      width: 80px;
      bottom: 0;
      top: 0;
      height: 100%;
      background-color: $white;
      transform: skew(-3deg, 0deg);
    }
  }

  &__content {
    @include desktop {
      padding-left: 100px;
    }
  }
}

:deep(.login-form) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  height: 100%;
  max-width: 528px;
  margin: 0 auto;

  @include tablet {
    margin: 0;
  }

  .login-form__header {
    margin-bottom: 40px;

    &--small {
      margin-bottom: 20px;

      .login-form__title {
        font-size: 32px;
        line-height: 40px;
      }

      .login-form__subtitle {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .login-form__title {
    color: $cabbage-point;
    font-weight: bold;
    line-height: 60px;
  }

  .login-form__subtitle {
    color: $cabbage-point;
    margin-top: 5px;
  }

  .login-form__sectiontitle {
    color: $cabbage-point;
    margin-top: 10px;
    font-weight: bold;
    letter-spacing: 0.73px;
    font-size: 1.375rem;
  }

  .login-form__description {
    color: $tundora;
    margin: 10px 0;
    font-size: 0.875rem;
  }

  .login-form__reset {
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 0.875rem;
    color: rgba($tundora, 0.7);
  }

  .login-form__back-link {
    font-weight: bold;
    .icon {
      transition: transform 0.2s;
    }

    &:hover {
      .icon {
        transform: translateX(2px);
      }
    }
  }

  .button {
    margin-top: 12px;
  }
}
</style>
