"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var Sentry = require("@sentry/vue");
var connectorErrorEnum_1 = require("@/constants/connectorErrorEnum");
function filterEditableConfigItems(value) {
    // If config is empty, don't add this config.
    if (value.config && value.config.length === 0) {
        return null;
    }
    // Only add editable config items.
    value.config = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(value.config).filter(function (_a) {
        var key = _a[0], item = _a[1];
        return item.editable;
    }));
    // Don't show connector with empty config
    if (Object.values(value.config).length === 0) {
        return null;
    }
    return value;
}
var Connector = {
    namespaced: true,
    state: {
        connectors: [],
        connectorConfigs: [],
        error: (_a = {},
            _a[connectorErrorEnum_1.default.PATCH] = false,
            _a[connectorErrorEnum_1.default.CONNECTOR_CONFIG] = false,
            _a[connectorErrorEnum_1.default.CONNECTORS] = false,
            _a),
    },
    mutations: {
        SET_ERROR: function (state, value) {
            state.error[value.type] = value.value;
        },
        SET_CONNECTORS: function (state, value) {
            state.connectors = value;
        },
        ADD_CONNECTOR_CONFIG: function (state, value) {
            value = filterEditableConfigItems(value);
            if (!value) {
                return;
            }
            state.connectorConfigs.push(value);
        },
        EDIT_CONNECTOR_CONFIG: function (state, value) {
            value = filterEditableConfigItems(value);
            if (!value) {
                return;
            }
            var connectorConfigIndexToUpdate = state.connectorConfigs.findIndex(function (connectorConfig) { return connectorConfig.connector === value.connector; });
            if (connectorConfigIndexToUpdate !== -1) {
                state.connectorConfigs[connectorConfigIndexToUpdate] = value;
            }
        },
    },
    actions: {
        getConnectors: function (_a) {
            var commit = _a.commit, getters = _a.getters;
            return __awaiter(this, void 0, Promise, function () {
                var configurationApi, apiResponse;
                return __generator(this, function (_b) {
                    configurationApi = getters.getConfigurationApi;
                    apiResponse = configurationApi.postConnectorsRequestCollection({
                        connectorsRequest: {
                            config: 'string',
                        },
                    });
                    return [2 /*return*/, apiResponse
                            .then(function (response) {
                            if (response.status === 200) {
                                commit('SET_CONNECTORS', response.data);
                            }
                            else {
                                throw new Error("Get connectors failed (status: ".concat(response.status, ")"));
                            }
                        })
                            .catch(function (e) {
                            commit('SET_ERROR', { type: connectorErrorEnum_1.default.CONNECTORS, value: true });
                            Sentry.captureException(e);
                        })];
                });
            });
        },
        getConnectorConfig: function (_a, id) {
            var commit = _a.commit, getters = _a.getters;
            return __awaiter(this, void 0, Promise, function () {
                var connectorApi, apiResponse;
                return __generator(this, function (_b) {
                    connectorApi = getters.getConnectorApi;
                    apiResponse = connectorApi.getConnectorConfigItem({
                        connector: id,
                    });
                    return [2 /*return*/, apiResponse
                            .then(function (response) {
                            if (response.status === 200) {
                                commit('ADD_CONNECTOR_CONFIG', response.data);
                            }
                            else {
                                throw new Error("Get connector config failed (status: ".concat(response.status, ")"));
                            }
                        })
                            .catch(function (e) {
                            commit('SET_ERROR', { type: connectorErrorEnum_1.default.CONNECTOR_CONFIG, value: true });
                            Sentry.captureException(e);
                        })];
                });
            });
        },
        getConnectorConfigs: function (_a) {
            var dispatch = _a.dispatch, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, dispatch('getConnectors')];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, Promise.all(state.connectors.map(function (connector) { return dispatch('getConnectorConfig', connector.id); }))];
                        case 2:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        patchConnectorConfig: function (_a, data) {
            var getters = _a.getters, commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var connectorApi, apiResponse;
                return __generator(this, function (_b) {
                    connectorApi = getters.getConnectorApi;
                    apiResponse = connectorApi.patchConnectorConfigItem({ connector: data.connector, connectorConfig: { config: data.config } });
                    return [2 /*return*/, apiResponse
                            .then(function (response) {
                            if (response.status === 200) {
                                commit('EDIT_CONNECTOR_CONFIG', response.data, true);
                            }
                            else {
                                throw new Error("Patching connector config failed (status: ".concat(response.status, ")"));
                            }
                        })
                            .catch(function (e) {
                            Sentry.captureException(e);
                            commit('SET_ERROR', { type: connectorErrorEnum_1.default.PATCH, value: true });
                        })];
                });
            });
        },
    },
    getters: {
        getConfigurationApi: function (state, getters, rootState, rootGetters) {
            return new api_1.ConfigurationApi(rootGetters.getConfiguration);
        },
        getConnectorApi: function (state, getters, rootState, rootGetters) {
            return new api_1.ConnectorApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Connector;
