"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'ResetPassword',
    data: function () {
        return {
            password: '',
        };
    },
    methods: {
        submit: function () {
            var resetData = {
                plainPassword: this.password,
                resetToken: this.$route.params.token,
            };
            this.$store.dispatch('reset/submit', {
                data: resetData,
                router: this.$router,
            });
        },
    },
});
