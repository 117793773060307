"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-7f3b4494"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "onboarding-details" };
var _hoisted_2 = {
    key: 0,
    class: "form-block"
};
var _hoisted_3 = { class: "onboarding-details__row" };
var _hoisted_4 = { class: "onboarding-details__text" };
var _hoisted_5 = { class: "onboarding-details__inputs" };
var _hoisted_6 = {
    key: 1,
    class: "form-block"
};
var _hoisted_7 = { class: "onboarding-details__row" };
var _hoisted_8 = { class: "onboarding-details__text" };
var _hoisted_9 = { class: "onboarding-details__inputs" };
var _hoisted_10 = { class: "onboarding-details__row" };
var _hoisted_11 = { class: "onboarding-details__text" };
var _hoisted_12 = { class: "onboarding-details__footer" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var vue_router_1 = require("vue-router");
var vee_validate_1 = require("vee-validate");
var text_field_vue_1 = require("@/components/form/fields/text_field.vue");
var select_field_vue_1 = require("@/components/form/fields/select_field.vue");
var AuthenticationFeedback_vue_1 = require("@/components/onboarding/feedback/AuthenticationFeedback.vue");
var oruga_next_1 = require("@oruga-ui/oruga-next");
var onboarding_steps_1 = require("@/constants/onboarding-steps");
var clientStatus_1 = require("@/constants/clientStatus");
exports.default = (0, vue_1.defineComponent)({
    __name: 'OnboardingDetails',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var route = (0, vue_router_1.useRoute)();
        var router = (0, vue_router_1.useRouter)();
        var oruga = (0, oruga_next_1.useProgrammatic)().oruga;
        var companyDetails = (0, vue_3.reactive)({
            name: '',
            email: '',
            address: '',
            city: '',
            postalCode: '',
            locale: 'nl-NL',
        });
        var firstname = (0, vue_3.ref)('');
        var lastname = (0, vue_3.ref)('');
        var currentStep = (0, vue_3.computed)(function () { return store.state.onboarding.step; });
        var languages = (0, vue_3.computed)(function () { return store.state.translationLocales.translationLocales; });
        var openModal = function (success) {
            oruga.modal.open({
                component: AuthenticationFeedback_vue_1.default,
                hasModalCard: false,
                width: '30%',
                trapFocus: true,
                props: { success: success },
                canCancel: false,
            });
        };
        var next = function () { return __awaiter(_this, void 0, Promise, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (currentStep.value === onboarding_steps_1.default.COMPANY_DETAILS) {
                            store.dispatch('onboarding/setStep', onboarding_steps_1.default.ACCOUNT_DETAILS);
                            return [2 /*return*/];
                        }
                        if (!(currentStep.value === onboarding_steps_1.default.ACCOUNT_DETAILS)) return [3 /*break*/, 1];
                        store.dispatch('onboarding/setStep', onboarding_steps_1.default.MAILBOX_AUTHENTICATION);
                        return [3 /*break*/, 5];
                    case 1:
                        companyDetails.name = "".concat(firstname.value, " ").concat(lastname.value);
                        companyDetails.email = store.state.users.currentUser.client.emailAddress;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, store.dispatch('onboarding/setCompanyDetails', { companyDetails: companyDetails })];
                    case 3:
                        _a.sent();
                        openModal(true);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        openModal(false);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var previous = function () {
            store.dispatch('onboarding/setStep', onboarding_steps_1.default.COMPANY_DETAILS);
        };
        var checkOnboardingStatus = function () { return __awaiter(_this, void 0, Promise, function () {
            var status;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.state.users.currentUser.client];
                    case 1:
                        status = (_a.sent()).status;
                        if (status === clientStatus_1.READY) {
                            router.push({ name: 'Home' });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (route.path.includes('deploying') || route.path.includes('error')) {
            var success = !!route.path.includes('deploying');
            store.dispatch('onboarding/setStep', onboarding_steps_1.default.MAILBOX_AUTHENTICATION);
            openModal(success);
        }
        setTimeout(function () {
            checkOnboardingStatus();
        }, 10000);
        return function (_ctx, _cache) {
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createVNode)((0, vue_2.unref)(vee_validate_1.Form), { onSubmit: next }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (currentStep.value === (0, vue_2.unref)(onboarding_steps_1.default).COMPANY_DETAILS)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                                    (0, vue_2.createElementVNode)("span", _hoisted_4, (0, vue_2.toDisplayString)("".concat(_ctx.$t('general.address'), " *")), 1),
                                    (0, vue_2.createElementVNode)("div", null, [
                                        (0, vue_2.createVNode)(text_field_vue_1.default, {
                                            id: "address",
                                            modelValue: companyDetails.address,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((companyDetails.address) = $event); }),
                                            placeholder: _ctx.$t('general.address'),
                                            name: _ctx.$t('general.address'),
                                            rules: "required"
                                        }, null, 8, ["modelValue", "placeholder", "name"]),
                                        (0, vue_2.createElementVNode)("div", _hoisted_5, [
                                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                                id: "postal",
                                                modelValue: companyDetails.postalCode,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((companyDetails.postalCode) = $event); }),
                                                placeholder: _ctx.$t('general.postal'),
                                                name: _ctx.$t('general.postal'),
                                                rules: "required"
                                            }, null, 8, ["modelValue", "placeholder", "name"]),
                                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                                id: "city",
                                                modelValue: companyDetails.city,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((companyDetails.city) = $event); }),
                                                placeholder: _ctx.$t('general.town'),
                                                name: _ctx.$t('general.town'),
                                                rules: "required"
                                            }, null, 8, ["modelValue", "placeholder", "name"])
                                        ])
                                    ])
                                ])
                            ]))
                            : (0, vue_2.createCommentVNode)("", true),
                        (currentStep.value === (0, vue_2.unref)(onboarding_steps_1.default).ACCOUNT_DETAILS)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_6, [
                                (0, vue_2.createElementVNode)("div", _hoisted_7, [
                                    (0, vue_2.createElementVNode)("span", _hoisted_8, (0, vue_2.toDisplayString)("".concat(_ctx.$t('general.name'), " *")), 1),
                                    (0, vue_2.createElementVNode)("div", _hoisted_9, [
                                        (0, vue_2.createVNode)(text_field_vue_1.default, {
                                            id: "firstname",
                                            modelValue: firstname.value,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((firstname).value = $event); }),
                                            name: _ctx.$t('general.firstname'),
                                            rules: "required"
                                        }, null, 8, ["modelValue", "name"]),
                                        (0, vue_2.createVNode)(text_field_vue_1.default, {
                                            id: "lastName",
                                            modelValue: lastname.value,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((lastname).value = $event); }),
                                            name: _ctx.$t('general.lastname'),
                                            rules: "required"
                                        }, null, 8, ["modelValue", "name"])
                                    ])
                                ]),
                                (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                    (0, vue_2.createElementVNode)("span", _hoisted_11, (0, vue_2.toDisplayString)("".concat(_ctx.$t('general.language'), " *")), 1),
                                    (0, vue_2.createVNode)(select_field_vue_1.default, {
                                        id: "language",
                                        modelValue: companyDetails.locale,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((companyDetails.locale) = $event); }),
                                        class: "onboarding-details__select",
                                        name: _ctx.$t('general.language'),
                                        placeholder: _ctx.$t('general.language'),
                                        options: languages.value,
                                        "option-value-field": "locale",
                                        rules: "required"
                                    }, null, 8, ["modelValue", "name", "placeholder", "options"])
                                ])
                            ]))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createElementVNode)("div", _hoisted_12, [
                            (currentStep.value === (0, vue_2.unref)(onboarding_steps_1.default).ACCOUNT_DETAILS)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_o_button, {
                                    key: 0,
                                    id: "previous",
                                    variant: "cancel",
                                    onClick: previous,
                                    class: "onboarding-details__button",
                                    label: _ctx.$t('general.back')
                                }, null, 8, ["label"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createVNode)(_component_o_button, {
                                id: "next",
                                variant: "active",
                                "native-type": "submit",
                                class: "onboarding-details__button",
                                label: _ctx.$t('general.next_step')
                            }, null, 8, ["label"])
                        ])
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
