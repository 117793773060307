"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var BaseFeedback_vue_1 = require("@/components/onboarding/feedback/BaseFeedback.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MailInUse',
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(BaseFeedback_vue_1.default, null, {
                main: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createElementVNode)("strong", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.mail_in_use.title')), 1),
                    (0, vue_2.createElementVNode)("p", null, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.mail_in_use.text')) + " ", 1),
                        (0, vue_2.createElementVNode)("strong", null, [
                            (0, vue_2.createVNode)(_component_router_link, { to: { name: 'Login' } }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.mail_in_use.login_page')), 1)
                                ]; }),
                                _: 1
                            })
                        ])
                    ]),
                    (0, vue_2.createElementVNode)("p", null, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.forgot_password.text')) + " ", 1),
                        (0, vue_2.createElementVNode)("strong", null, [
                            (0, vue_2.createVNode)(_component_router_link, { to: { name: 'Forgot Password' } }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.forgot_password.link')), 1)
                                ]; }),
                                _: 1
                            })
                        ]),
                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.forgot_password.function')), 1)
                    ])
                ]; }),
                _: 1
            }));
        };
    }
});
