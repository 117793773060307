"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'ForgotPassword',
    data: function () {
        return {
            username: '',
        };
    },
    mounted: function () {
        if (typeof this.$route.query.username === 'string') {
            this.username = this.$route.query.username;
        }
    },
    methods: {
        submit: function () {
            var requestData = { email: this.username };
            this.$store.dispatch('forgot/submit', requestData);
        },
    },
});
