"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/api");
var Sentry = require("@sentry/vue");
var Reset = {
    namespaced: true,
    state: {
        isLoading: false,
        error: false,
        isSent: false,
    },
    mutations: {
        attempt: function (state) {
            state.error = false;
            state.isLoading = true;
        },
        fail: function (state) {
            state.error = true;
        },
        complete: function (state) {
            state.isLoading = false;
            state.error = false;
            state.isSent = true;
        },
    },
    actions: {
        submit: function (context, payload) {
            var authenticationApi = context.getters.getAuthenticationApi;
            var commit = context.commit;
            commit('attempt');
            var apiResponse = authenticationApi.postPasswordResetCollection({
                passwordReset: payload.data,
            });
            apiResponse
                .then(function () {
                commit('complete');
                setTimeout(function () { return payload.router.push({ path: '/login' }); }, 5000);
            })
                .catch(function (error) {
                Sentry.captureException(error);
                commit('fail');
            });
        },
    },
    getters: {
        getAuthenticationApi: function (state, getters, rootState, rootGetters) {
            return new api_1.AuthenticationApi(rootGetters.getConfiguration);
        },
    },
};
exports.default = Reset;
