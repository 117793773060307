"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-e56a83de"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "mail-available" };
var _hoisted_2 = { class: "mail-available__title" };
var _hoisted_3 = { class: "mail-available__form mail-available__form--no-wrap" };
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var vee_validate_1 = require("vee-validate");
var text_field_vue_1 = require("@/components/form/fields/text_field.vue");
var BaseFeedback_vue_1 = require("@/components/onboarding/feedback/BaseFeedback.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MailAvailable',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var companyDetails = (0, vue_3.ref)({
            name: '',
            address: '',
            postal: '',
            town: '',
        });
        var toNextPage = function () {
            store.dispatch('onboarding/setCompanyDetails', companyDetails.value);
        };
        return function (_ctx, _cache) {
            var _component_o_icon = (0, vue_2.resolveComponent)("o-icon");
            var _component_o_button = (0, vue_2.resolveComponent)("o-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(_component_o_icon, {
                        class: "mail-available__icon",
                        pack: "giraf",
                        icon: "business",
                        "custom-size": "8px"
                    }),
                    (0, vue_2.createElementVNode)("strong", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.company_details')), 1)
                ]),
                (0, vue_2.createVNode)((0, vue_2.unref)(vee_validate_1.Form), {
                    class: "mail-available__form",
                    onSubmit: toNextPage
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(text_field_vue_1.default, {
                            name: _ctx.$t('general.company_name'),
                            id: "company_name",
                            class: "mail-available__form-field",
                            modelValue: _ctx.company_details.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.company_details.name) = $event); }),
                            placeholder: "".concat(_ctx.$t('general.company_name'), " *"),
                            rules: "required"
                        }, null, 8, ["name", "modelValue", "placeholder"]),
                        (0, vue_2.createVNode)(text_field_vue_1.default, {
                            name: _ctx.$t('general.address'),
                            id: "address",
                            class: "mail-available__form-field",
                            modelValue: _ctx.company_details.address,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((_ctx.company_details.address) = $event); }),
                            placeholder: "".concat(_ctx.$t('general.address'), " *"),
                            rules: "required"
                        }, null, 8, ["name", "modelValue", "placeholder"]),
                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                name: _ctx.$t('general.postal'),
                                id: _ctx.$t('general.postal'),
                                class: "mail-available__form-field",
                                modelValue: _ctx.company_details.postal,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((_ctx.company_details.postal) = $event); }),
                                placeholder: "".concat(_ctx.$t('general.postal'), " *"),
                                rules: "required"
                            }, null, 8, ["name", "id", "modelValue", "placeholder"]),
                            (0, vue_2.createVNode)(text_field_vue_1.default, {
                                name: _ctx.$t('general.town'),
                                id: "town",
                                class: "mail-available__form-field",
                                modelValue: _ctx.company_details.town,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((_ctx.company_details.town) = $event); }),
                                placeholder: "".concat(_ctx.$t('general.town'), " *"),
                                rules: "required"
                            }, null, 8, ["name", "modelValue", "placeholder"])
                        ]),
                        (_ctx.valid)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(BaseFeedback_vue_1.default, { key: 0 }, {
                                main: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.mail_available.text')), 1),
                                    (0, vue_2.createElementVNode)("strong", null, (0, vue_2.toDisplayString)(_ctx.$t('onboarding.introduction.email_check.mail_available.duration')), 1)
                                ]; }),
                                _: 1
                            }))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createVNode)(_component_o_button, {
                            variant: _ctx.valid ? 'active' : 'non-active',
                            "native-type": "submit",
                            class: "mail-available__button",
                            id: "login",
                            label: _ctx.$t('onboarding.introduction.email_check.next_page')
                        }, null, 8, ["variant", "label"])
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
