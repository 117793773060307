"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var store_1 = require("@/store");
var router_1 = require("@/router");
axios_1.default.defaults.withCredentials = true;
if ((_b = (_a = store_1.default.state.users.currentUser) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.id) {
    axios_1.default.defaults.headers.common['Client-Id'] = store_1.default.state.users.currentUser.client.id;
}
axios_1.default.interceptors.response.use(function (response) { return response; }, function (error) {
    if (error.response
        && error.response.status
        && error.response.status === 401
        && (error.response.data.message === 'Expired JWT Token'
            || error.response.data.message === 'JWT Token not found')) {
        store_1.default.dispatch('resetStore');
        router_1.default.push('/login');
        return Promise.resolve(error.response);
    }
    return Promise.reject(error);
});
